import {combineReducers} from 'redux'

import loginReducer from './Login/reducer'
import mainReducer from './Main/reducer'
import zoneReducer from './Zone/reducer'

import stateReducer from './State/reducer'

import resultsReducer from './Dashboard/reducer'
import resultsReducer2 from './Dashboard2/reducer'
import lgaReducer from './LGA/reducer'
import wardReducer from './Ward/reducer'
import pollingUnitReducer from './PollingUnits/reducer'
import userReducer from './User/reducer'

export default combineReducers({
    loginReducer,mainReducer, zoneReducer, stateReducer, resultsReducer, lgaReducer, wardReducer,pollingUnitReducer, userReducer,resultsReducer2
})
