import React from 'react';
import { Route } from 'react-router-dom';

import Login from './components/Login';

export default () => (
    <switch>
        <Route path='/login' component={Login} />
        <Route path='/register' component={Login} />
    </switch>
);
