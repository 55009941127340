import axios from "axios";
import config from '../config'
import request from './../request'

var loginSource = {

    fetchUsers: function () {
        return request.post(`api/users`,{},{})
    },

    loginUser: function (auth) {
        return  request.post(`auth/login`, auth);
    },

    registerUser: function (reqParam) {
        return request.post('/user',reqParam)
    },

    fetchUser: function (id) {
        return request.get('/user/'+id)
    }
};


//
//
//
// const get = () =>
//
// export { get };

export default loginSource;
