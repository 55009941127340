import React from 'react'

import '../../Main/static/css/dashboard.css'
import CreateUser from "./CreateUser";
import UsersList from "./UsersList";
import SearchBox from "./SearchBox";

const AddUsers = () => {
    return (
        <main role="main" className="col-md-9 ml-sm-auto col-lg-10 px-md-4">
            <CreateUser/>
            <SearchBox/>
            <UsersList/>
        </main>
    )
}

export default AddUsers
