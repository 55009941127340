import React, {Component, useEffect, useState} from 'react'
import _get from 'lodash/get'
import { Link, withRouter, Redirect } from 'react-router-dom'
import connect from 'react-redux/es/connect/connect'
import { loginUser, removeUserInput, setUserInput } from '../actions'
import {Input,LoadingLine,Auth} from 'common-modules'
import {useDispatch, useSelector} from "react-redux";
import './../../Main/static/css/Main.css'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye, faEyeSlash, faLocationDot, faTrash} from "@fortawesome/free-solid-svg-icons";

const _Login = (props) => {

    const [validationError, setValidationError] = useState('');
    const [userReq,setUserReq] = useState({})
    const [isShowPassword,setIsShowPassword] = useState(false)
    const dispatch = useDispatch()
    const {userInput,users, fetchState, loginState, loginError} = useSelector(state => state.loginReducer)

  const loginOnClick = (e) =>{

    const user = {...userReq}
    console.log(user)
    if (!user.username || !user.password) {
      setValidationError('Fill all required fields')
      return
    }

    dispatch(loginUser(user))
      .then(result => {
        const userData = _get(result, 'action.payload.data');
        if(userData.role === "AGENT") {
            setValidationError('Agent can login only with mobile app')
            return
        }
        Auth.saveUser(userData)
        if(userData.id)
          Auth.saveToken(userData.id)
        props.history.push('/dashboard')
      }).catch(err => console.log('err', err))
  }

  const handleOnChange = (value,refer) => {
    userReq[refer] = value;
    console.log(value,refer)
    setUserReq(userReq)
  }

    useEffect(()=>{
        dispatch(removeUserInput())
    },[])


        return (


            <div className="col-md-12 text-center " style={{justifyContent:'center',display: 'flex'}}>
                <div className='col-md-4'>
                    <div className="card bg-light mb-3" style={{maxWidth:'100%'}}>
                        <div className="card-header pb-0">
                            <h1 className="f4 bg-near-white br3 br--top black-60 mv0 pv2 ph3">Login</h1></div>
                        <div className="card-body">
                            <div className="pa3 justify-center w-100 bt b--black-10">
                                <Input
                                    refer='username'
                                    handleOnChange={handleOnChange}
                                    value={userReq.username}
                                    placeholder='Username ...'/>
                            </div>
                            <div className="row" style={{paddingLeft:30}}>
                                <div style={{marginLeft:0,padding:0}} className='col-md-10'>
                                    <Input
                                        style={{width:'75%'}}
                                        inputType={isShowPassword ? 'text' : 'password'}
                                        refer='password'
                                        className='form-control'
                                        handleOnChange={handleOnChange}
                                        value={userReq.password}
                                        placeholder='Password ...'/>
                                </div>
                                <div style={{marginLeft:0,padding:'0'}} className='col-md-2'>
                                    <button type='button' className='btn btn-outline-dark'
                                            onClick={()=>setIsShowPassword(!isShowPassword)}>
                                        <FontAwesomeIcon
                                            icon={isShowPassword ?   faEyeSlash : faEye}/></button>
                                </div>
                            </div>
                            <div className="fl-lpa3 text-center w-100 bt b--black-10 mt-2">
                                <a className=" mt-2 f6 w-50 grow no-underline br-pill ph3 pv2 mb2 dib hover-white white bg-dark-green"
                                   style={{textDecoration: 'none'}} href="javascript: void(0)"
                                   onClick={loginOnClick} >Login</a>
                            </div>
                        </div>
                        {
                            loginState === 1 &&
                            <div className="row">
                                <div className="col-md-12">
                                    <LoadingLine/>
                                </div>
                            </div>
                        }

                        {
                            loginState === 3 &&
                            <b className='light-red'> {loginError}</b>
                        }

                        {
                            validationError &&
                            <div className="row">
                                <div className="col">
                                    <b className='light-red'>{validationError}</b>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        )

}

export default _Login
