import React, {Component, useEffect, useState} from 'react';
import {connect, useDispatch, useSelector} from 'react-redux';
import queryString from 'query-string';
import { withRouter, Link } from 'react-router-dom';
import {loginUser,setUserInput,removeUserInput,fetchUser} from "./../actions";
import background from './../static/img/background.jpeg';
import {Input,LoadingLine,Auth} from 'common-modules'
import _get from 'lodash/get'
import _Register from './_Register'
import _Login from './_Login'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap'
import './../../Main/static/css/Main.css'
window.$ = window.jQuery = require('jquery')
window.Popper = require('popper.js').default

const Login = (props) =>  {

    const [validationError,setValidationError] = useState('')
    const [isSignUp,setIsSignUp] = useState(true)
    const [userReq,setUserReq] = useState({})
    const dispatch = useDispatch()


    useEffect(()=>{
        props.location.pathname === '/login' ? setIsSignUp(false) : setIsSignUp(true)
        dispatch(removeUserInput())
    },[])

    return (
            <div>
                <div className="vh-100 dt w-100 tc bg-dark-gray white cover"
                     style={{backgroundImage: `url(${background})`,marginTop:'-20px'}}>
                    <div className="dtc v-mid">
                        {!isSignUp &&
                            <_Login {...props}/>
                        }
                        {isSignUp &&
                            <article className="center mw5 mw6-ns br3 hidden ba mv4 bg-black-50">
                                <h1 className="f4 bg-near-white br3 br--top black-60 mv0 pv2 ph3">Register</h1>
                                <div className="pa3 tc w-100 bt b--black-10">
                                    <_Register/>
                                </div>

                            </article>
                        }
                    </div>
                </div>
            </div>


        );
}

export default Login
