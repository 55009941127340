import _, { keyBy ,get} from 'lodash'

const initialState = {
    user: {},
    createState: 0,
    users: [],
    fetchState: 0,
    getOneState:0,
    assignPollingUnitState:0,
    assignStateState:0,
    error: {}
}

const reducer = (state = initialState, action) => {
    switch (action.type) {

        case 'CREATE_USER_PENDING':
            return { ...state, createState: 1 }
        case 'CREATE_USER_FULFILLED':
            const user = action.payload.data
            return {
                ...state,
                createState: 2,
                user: {},
                users: [...state.users,user]
            }
        case 'CREATE_USER_REJECTED':
            return {
                ...state,
                createState: 3,
                error:  _.get(action.payload,['response','data'])
            }

        case 'GET_USER_BY_ID_PENDING':
            return { ...state, getOneState: 1 }
        case 'GET_USER_BY_ID_FULFILLED':

            return { ...state, getOneState: 2, user:action.payload.data}
        case 'GET_USER_BY_ID_REJECTED':
            return {
                ...state,
                getOneState: 3,
                error: { ...state.error, fetch: get(action.payload,['response','data']) }
            }

        case 'SEARCH_USERS_PENDING':
            return { ...state, fetchState: 1 }
        case 'SEARCH_USERS_FULFILLED':
            console.log("action.payload.data",action.payload.data)
            return { ...state, fetchState: 2, users: action.payload.data}
        case 'SEARCH_USERS_REJECTED':
            return {
                ...state,
                fetchState: 3,
                error: { ...state.error, fetch: get(action.payload,['response','data']) }
            }

        case 'ASSIGN_POLLING_UNIT_PENDING':
            return { ...state, assignPollingUnitState: 1 }
        case 'ASSIGN_POLLING_UNIT_FULFILLED':

            return {
                ...state,
                assignPollingUnitState: 2
            }
        case 'ASSIGN_POLLING_UNIT_REJECTED':
            return {
                ...state,
                assignPollingUnitState: 3,
                error: { ...state.error, create: get(action.payload,['response','data']) }
            }

        case 'ASSIGN_STATES_PENDING':
            return { ...state, assignStateState: 1 }
        case 'ASSIGN_STATES_FULFILLED':

            return {
                ...state,
                assignStateState: 2
            }
        case 'ASSIGN_STATES_REJECTED':
            return {
                ...state,
                assignStateState: 3,
                error: { ...state.error, create: get(action.payload,['response','data']) }
            }

        case 'DELETE_USER_PENDING':
            console.log(action)
            return { ...state, fetchState: 1 }
        case 'DELETE_USER_FULFILLED':
                console.log(action)
            return { ...state, fetchState: 2, users: _.filter(state.users,e => e.id !== action.payload.data)}
        case 'DELETE_USER_REJECTED':
            console.log(action)
            return {
                ...state,
                fetchState: 3,
                error: { ...state.error, fetch: get(action.payload,['response','data']) }
            }

        default:
            return state
    }
}

export default reducer
