import React, {Fragment, useEffect} from 'react'
import _, {get} from 'lodash'
import {connect, useDispatch} from 'react-redux'
import { useHistory } from "react-router-dom";
import {searchStates} from '../actions'
import {searchZones} from "../../Zone/actions";

const StatesList = props => {
    const dispatch = useDispatch()
    const history = useHistory()
    const { fetchState, error, states } = props.stateReducer

    useEffect(() => {
        // dispatch(searchStates())
        // dispatch(searchZones())
    }, [])


    return (
        <Fragment>
            {
                fetchState === 2 &&
                <div className="my-3 p-3 bg-white rounded shadow-sm">
                    <div className='row'>
                    {
                        states.map(state => {
                            return (
                                <div className='col-md-3'>
                                    <div className="card">
                                        <div className="card-body">
                                            <h5 className="card-title">{state.name}</h5>
                                            <h6 className="card-title">{state.code}</h6>
                                            Zone: <span className='badge badge-primary'>{_.get(state,'zone.name')}</span>
                                        </div>
                                        <div className='card-footer text-center'>
                                            <button type="button " className="btn btn-warning" onClick={e=>history.push('/states/'+state.id)} >
                                                Edit
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                    </div>
                </div>
            }

            {
                fetchState === 1 &&
                <div className="row ">
                    <div className="col-md-12 text-center">
                        <div className="spinner-border text-primary" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                </div>
            }

            {
                fetchState === 3 &&
                <div className="alert alert-danger alert-dismissible fade show mt-1" role="alert">
                    {get(error, 'create')}
                    <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            }
            {fetchState === 2 && Object.values(states).length < 1 &&
                <div className="alert alert-warning" role="alert">
                    No states found
                </div>
            }
        </Fragment>
    )
}

export default connect(state => ({
    stateReducer:state.stateReducer
}))(StatesList)
