import request from '../request'

export const create = pollingUnit => {
    return request.post('polling-units', pollingUnit)
}

export const search = (query) => {
    const searchParams = new URLSearchParams(query);
    return request.get(`polling-units?${searchParams}`)
}

export const getPollingUnitById = id => {
    return request.get('polling-units/'+ id)
}

export const updatePollingUnit = (id,req) => {
    return request.patch('polling-units/'+ id,req)
}
