import React, {Fragment, useEffect, useRef, useState} from 'react'
import _, {get, groupBy} from 'lodash'
import {connect, useDispatch} from 'react-redux'
import $ from 'jquery'
import {deleteGpsData, searchPassRate, searchResults, searchResultSummary, updateGpsData} from '../actions'
import {Input} from "common-modules";
import resultsReducer from "../reducer";
import {getUsersRole, getUsersSchool, isAdmin, isAdminUser} from "../../Utils/utils";
import moment from "moment";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faCheckCircle, faMap, faPencil, faTimesCircle, faTrash} from "@fortawesome/free-solid-svg-icons";
import {updateUser} from "../../User/actions";
import {useHistory} from "react-router-dom";

const ResultsList = props => {
    const dispatch = useDispatch()
    const history = useHistory()
    const { fetchResultState, error ,results, gpsDataList, isDownloadingState } = props.resultsReducer
    const [selectedId,setSelectedId] = useState(null)
    const [remark,setRemark] = useState("null Remarked text")


    const [updateReq,setUpdateReq] = useState({})

    const onEdit = (data,status) => {
        setSelectedId(data.id)
        if(status){
            setUpdateReq({ status: status})
            $('#updateModal').modal('show')
        }else{
            $('#remarkModal').modal('show')
            setRemark(data.remark)
        }

    }
    const onUpdate = () => {
       dispatch(updateGpsData(selectedId,updateReq)).then(res=>{
           $('#updateModal').modal('hide')
           $('#remarkModal').modal('hide')
           setUpdateReq({})
       })
    }

    const handleOnChange = (value,refer) => {
        updateReq[refer] = value
        setUpdateReq({...updateReq})
    }

    const confirmDelete = (data) =>{
        setSelectedId(data.id)
        $('#deleteModal').modal('show')
    }
    const onDelete = () =>{
        dispatch(deleteGpsData(selectedId))
        $('#deleteModal').modal('hide')
    }


    return (
        <Fragment>
            {fetchResultState === 2 &&
                <div className="my-3 p-3 bg-white rounded shadow-sm" style={{overflowX:'auto'}}>
                    <table className="table" id='gps-data-table'>
                        <thead className="thead-dark">
                        <tr>
                            {!isDownloadingState &&
                                <th/>
                            }
                            <th style={{width:'20%' , whiteSpace: 'pre'}}>Submitted Date</th>
                            <th>Firstname</th>
                            <th>Lastname</th>
                            <th>Email</th>
                            <th>Phone Number</th>
                            <th>Zone</th>
                            <th>State</th>
                            <th>State code</th>
                            <th>LGA</th>
                            <th>Ward</th>
                            <th>Ward code</th>
                            <th>PU</th>
                            <th>PU Code</th>
                            <th>Landmark</th>
                            <th>Description</th>
                            <th>GEO coordinate(X)</th>
                            <th>GEO coordinate(Y)</th>
                            <th>Image</th>
                            <th>Facility Type</th>
                            <th>Comments</th>
                            {!isDownloadingState &&
                                <th>Remark</th>
                            }
                        </tr>
                        </thead>
                        <tbody>
                        {gpsDataList.map(gpsData => {


                                return (
                                    <tr>
                                        {getUsersRole() === "SUPERVISOR" &&
                                            <td style={{whiteSpace: 'nowrap'}}>
                                                {gpsData.status === "APPROVED" &&
                                                    <span className='badge badge-success mr-1'>APPROVED</span>
                                                }
                                                {gpsData.status === "REJECTED" &&
                                                    <span className='badge badge-danger mr-1'>REJECTED</span>
                                                }
                                                <button type='button' className='btn btn-outline-success'
                                                        onClick={() => onEdit(gpsData,"APPROVED")}><FontAwesomeIcon
                                                    icon={faCheckCircle}/></button>
                                                <button type='button' className='btn btn-outline-danger ml1'
                                                        onClick={() => onEdit(gpsData,"REJECTED")}><FontAwesomeIcon
                                                    icon={faTimesCircle}/></button>
                                                <button type='button' className='btn btn-outline-success ml1'
                                                        onClick={() => {
                                                            const win = window.open(`/map-view?longitude=${gpsData.longitude}&latitude=${gpsData.latitude}&pu=${_.get(gpsData,'pollingUnit.name',"Unknown")}`, "_blank");
                                                            win.focus();
                                                        }}>
                                                    <FontAwesomeIcon
                                                        icon={faMap}/></button>
                                            </td>
                                        }
                                        {(getUsersRole() === "COORDINATOR" ||  getUsersRole() === "QC") && !isDownloadingState &&
                                            <td style={{whiteSpace: 'nowrap'}}>
                                                {gpsData.status === "APPROVED" &&
                                                    <span className='badge badge-success mr-1'>APPROVED</span>
                                                }
                                                {gpsData.status === "REJECTED" &&
                                                    <span className='badge badge-danger mr-1'>REJECTED</span>
                                                }
                                                <button type='button' className='btn btn-outline-success'
                                                        onClick={() => {
                                                            const win = window.open(`/map-view?longitude=${gpsData.longitude}&latitude=${gpsData.latitude}&pu=${_.get(gpsData,'pollingUnit.name',"Unknown")}`, "_blank");
                                                            win.focus();
                                                        }}>
                                                    <FontAwesomeIcon
                                                    icon={faMap}/></button>
                                                {getUsersRole() === "COORDINATOR" &&
                                                    <button type='button' className='btn btn-outline-danger ml-1'
                                                            onClick={()=>confirmDelete(gpsData)}>
                                                        <FontAwesomeIcon
                                                            icon={faTrash}/></button>
                                                }
                                                {/*{getUsersRole() === "QC" &&*/}
                                                    <button type='button' className='btn btn-outline-primary ml-1'
                                                            onClick={()=>onEdit(gpsData)}>
                                                        <FontAwesomeIcon
                                                            icon={faPencil}/></button>
                                                {/*}*/}
                                                {getUsersRole() === "COORDINATOR" && gpsData.remark &&
                                                    <button type='button' className='btn btn-outline-primary ml-1'
                                                            onClick={()=>onEdit(gpsData)}>
                                                        <FontAwesomeIcon
                                                            icon={faCheckCircle} className='mr-1'/>
                                                        Remarked
                                                        </button>
                                                }
                                            </td>
                                        }
                                        <td>{moment(gpsData.collectedDate,"DD-MM-YYYY HH:mm Z").format("YYYY-MM-DD HH:mm") }</td>
                                        <td>{_.get(gpsData,'agent.firstName')}</td>
                                        <td>{_.get(gpsData,'agent.lastName')}</td>
                                        <td>{_.get(gpsData,'agent.email')}</td>
                                        <td>{_.get(gpsData,'agent.phoneNumber')}</td>
                                        <td>{_.get(gpsData,'pollingUnit.ward.lga.state.zone.name')}</td>
                                        <td>{_.get(gpsData,'pollingUnit.ward.lga.state.name')}</td>
                                        <td>{_.get(gpsData,'pollingUnit.ward.lga.state.code')}</td>
                                        <td>{_.get(gpsData,'pollingUnit.ward.lga.name')}</td>
                                        <td>{_.get(gpsData,'pollingUnit.ward.name')}</td>
                                        <td>{_.get(gpsData,'pollingUnit.ward.code')}</td>
                                        <td>{_.get(gpsData,'pollingUnit.name')}</td>
                                        <td>{_.get(gpsData,'pollingUnit.code')}</td>
                                        <td>{_.get(gpsData,'landmark')}</td>
                                        <td>{_.get(gpsData,'description')}</td>
                                        <td>{_.get(gpsData,'latitude')}</td>
                                        <td>{_.get(gpsData,'longitude')}</td>
                                        <td>{_.get(gpsData,'imageUrl')}</td>
                                        <td>{_.get(gpsData,'facilityType')}</td>
                                        <td>{_.get(gpsData,'comments')}</td>
                                        {!isDownloadingState &&
                                            <td>{_.get(gpsData, 'remark')}</td>
                                        }

                                    </tr>
                                )
                            })
                        }
                        </tbody>
                    </table>
                </div>
            }

            {
                fetchResultState === 1 &&
                <div className="row ">
                    <div className="col-md-12 text-center">
                        <div className="spinner-border text-primary" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                </div>
            }

            {
                fetchResultState === 3 &&
                <div className="alert alert-danger alert-dismissible fade show mt-1" role="alert">
                    {get(error, 'create')}
                    <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            }
            {fetchResultState !== 1 && gpsDataList.length < 1 &&
                <div className="alert alert-warning tc" role="alert">
                    No results found, Please use search filter to view data !!!
                </div>
            }
                <div id="updateModal" className="modal" tabIndex="-1" role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">{updateReq.status === "APPROVED" ? "Confirm Approve" : "Confirm Reject" }</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <p>{updateReq.status === "APPROVED" ? "Are you sure to approve this data ?" : "Are you sure to reject this data ?" }</p>
                                {/*<div className='mt-2'>*/}
                                {/*    <Input*/}
                                {/*        refer='facilityType'*/}
                                {/*        handleOnChange={handleOnChange}*/}
                                {/*        value={get(updateReq, 'facilityType')}*/}
                                {/*        placeholder='Facility Type'*/}
                                {/*        className="form-control mt-1"/>*/}
                                {/*</div>*/}
                                {/*<div className='mt-2'>*/}
                                {/*    <Input*/}
                                {/*        refer='comments'*/}
                                {/*        handleOnChange={handleOnChange}*/}
                                {/*        value={get(updateReq, 'comments')}*/}
                                {/*        placeholder='Comments'*/}
                                {/*        className="form-control mt-1"/>*/}
                                {/*</div>*/}
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                {updateReq.status === "APPROVED" ?
                                    <button type="button" className="btn btn-success"
                                            onClick={onUpdate}>Approve</button> :
                                    <button type="button" className="btn btn-danger" onClick={onUpdate}>Reject</button>
                                }
                            </div>
                        </div>
                    </div>
                </div>

            <div id="deleteModal" className="modal" tabIndex="-1" role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Confirm Delete</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                           <h5>Are you sure want to delete this entry ?</h5>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button type="button" className="btn btn-danger" onClick={onDelete}>Delete</button>
                        </div>
                    </div>
                </div>
            </div>

            <div id="remarkModal" className="modal" tabIndex="-1" role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{getUsersRole() === "QC" ?  "Update" : "Remark"}</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className='mt-2'>
                                {/*{getUsersRole() === "QC" ?*/}
                                    <Input
                                        refer='remark'
                                        handleOnChange={handleOnChange}
                                        value={get(updateReq, 'remark')}
                                        placeholder='Remark'
                                        className="form-control mt-1"/> :
                                        <p><b>{remark}</b></p>
                                {/*}*/}
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            {/*{getUsersRole() === "QC" &&*/}
                                <button type="button" className="btn btn-success"
                                        onClick={onUpdate}>Update</button>
                            {/*}*/}
                        </div>
                    </div>
                </div>
            </div>


        </Fragment>
    )
}

export default connect(state => ({
    resultsReducer: state.resultsReducer,
}))(ResultsList)
