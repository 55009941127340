import React, {Fragment, useEffect, useState} from 'react'
import _, {get} from 'lodash'
import {connect, useDispatch} from 'react-redux'

import {createUser, getUserById, updateUser} from '../actions'
import {Input, Select} from 'common-modules'
import {searchStates} from "../../State/actions";
import { useParams,useHistory } from "react-router-dom";
import {userRoles} from "../../Utils/utils";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPencil, faTrash} from "@fortawesome/free-solid-svg-icons";
const CreateUser = props => {
    const params = useParams()
    const history = useHistory()
    const dispatch = useDispatch()
    const { createState, error, user,users } = props.userReducer
    const [createReq,setCreateReq] = useState({})
    const userId = _.get(props,'match.params.id')
    const isEdit = _.get(props,'location.state.isEdit',false)
    const isDisableEdit = userId && !isEdit
    const [disablePassword,setDisablePassword ] = useState(!!userId)

    console.log(_.get(props,'location.state.isEdit'))

    const handleOnChange = (value,refer) => {
        createReq[refer] = value
        setCreateReq({...createReq})
    }
    const addUser = () => {
        dispatch(createUser(createReq))
    }

    const update = () =>{
        dispatch(updateUser(userId,createReq)).then(()=>{
            history.push('/users')
        })
    }

    useEffect(()=>{
        if(createState === 2)
            setCreateReq({})
    },[createState])

    useEffect(()=>{
        if(userId)
            dispatch(getUserById(userId)).then(response=>{
               setCreateReq(response.value.data)
            })
    },[userId])

    return (

        <Fragment>
            <div className={params.id ? 'row offset-md-2' : 'row'}>
                <div className="col-md-12 text-center mt-5 " style={{justifyContent:'center',display: 'flex'}}>
                        <div className='col-md-12'>
                            <div className="card bg-light mb-3" style={{maxWidth:'100%'}}>
                                <div className="card-header pb-0"><h3> {params.id ? "Update User" : "Add Users"}</h3></div>
                                <div className="card-body">
                                    <div className='row'>
                                    <div className='col-md-4 mt-2'>
                                        <Input
                                            refer='firstName'
                                            handleOnChange={handleOnChange}
                                            value={get(createReq, 'firstName')}
                                            placeholder='First Name'
                                            disabled={isDisableEdit}
                                            className="form-control mt-1"/>
                                    </div>
                                    <div className='col-md-4 mt-2'>
                                        <Input
                                            disabled={isDisableEdit}
                                            refer='lastName'
                                            handleOnChange={handleOnChange}
                                            value={get(createReq, 'lastName')}
                                            placeholder='Last Name'
                                            className="form-control mt-1"/>
                                    </div>
                                    <div className='col-md-4 mt-2'>
                                        <Input
                                            disabled={isDisableEdit}
                                            refer='username'
                                            handleOnChange={handleOnChange}
                                            value={get(createReq, 'username')}
                                            placeholder='Username'
                                            className="form-control mt-1"/>
                                    </div>
                                            <div className={userId && isEdit ? 'col-md-3 mt-2' : 'col-md-4 mt-2'}>
                                                <Input
                                                    refer='password'
                                                    handleOnChange={handleOnChange}
                                                    value={get(createReq, 'password')}
                                                    placeholder='Password'
                                                    disabled={disablePassword}
                                                    className="form-control mt-1"/>
                                            </div>
                                        {userId && isEdit &&
                                            <div className='col-md-1 mt2 ml0'>
                                                <button
                                                    onClick={() => setDisablePassword(!disablePassword)}
                                                    className='btn btn-warning'>
                                                    <FontAwesomeIcon
                                                        icon={faPencil}/>
                                                </button>
                                            </div>
                                        }
                                    <div className='col-md-4 mt-2'>
                                        <Input
                                            disabled={isDisableEdit}
                                            refer='email'
                                            handleOnChange={handleOnChange}
                                            value={get(createReq, 'email')}
                                            placeholder='Email'
                                            className="form-control mt-1"/>
                                    </div>
                                        <div className='col-md-4 mt-2'>
                                            <Input
                                                disabled={isDisableEdit}
                                                refer='phoneNumber'
                                                handleOnChange={handleOnChange}
                                                value={get(createReq, 'phoneNumber')}
                                                placeholder='Phone'
                                                className="form-control mt-1"/>
                                        </div>
                                        <div className='col-md-4 mt-2'>
                                            <Select
                                                disabled={isDisableEdit}
                                                refer='role'
                                                handleOnChange={handleOnChange}
                                                value={get(createReq, 'role')}
                                                placeholder='User Type'
                                                items = {userRoles}
                                                className="form-control mt-1"/>
                                        </div>
                                    </div>
                                </div>

                            {
                               !params.id && createState !== 1 &&
                                <div className="row text-center mb-2 " style={{justifyContent:'center',display: 'flex'}}>
                                    <div className="col-md-3 ">
                                        <button
                                            onClick={addUser}
                                            className='btn btn-outline-success btn-block'>
                                            Create
                                        </button>
                                    </div>
                                </div>
                            }

                            {userId && createState !== 1 &&
                                <div className="row text-center mb-2 " style={{justifyContent:'center',display: 'flex'}}>
                                    <div className="col-md-2 ">
                                        <button
                                            onClick={()=>history.goBack()}
                                            className='btn btn-outline-secondary btn-block'>
                                            Back
                                        </button>
                                    </div>
                                    {isEdit &&
                                        <div className="col-md-2 ">
                                            <button
                                                onClick={update}
                                                className='btn btn-outline-primary btn-block'>
                                                Update
                                            </button>
                                        </div>
                                    }
                                </div>
                            }

                            {
                                createState === 1 &&
                                <div className="row ">
                                    <div className="col-md-12 text-center">
                                        <div className="spinner-border text-primary" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                </div>
                            }

                            {
                                createState === 3 &&
                                <div className="alert alert-danger alert-dismissible fade show mt-1" role="alert">
                                    Create failed!
                                    <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                            }
                            </div>
                        </div>
                </div>
            </div>
        </Fragment>
    )
}

export default connect(state => ({
    userReducer : state.userReducer
}))(CreateUser)
