import React, {useRef} from 'react'

import '../../Main/static/css/dashboard.css'

import ResultsList from "./ResultsList";
import SearchDashboard from "./SearchDashboard";
import SummaryCard from "./SummaryCard";

const GPSData = () => {
    const tableRef = useRef(null);
    const getTableRef = () =>{
        return tableRef.current;
    }

    return (
        <main role="main" className="col-md-9 ml-sm-auto col-lg-10 px-md-4">
            <SearchDashboard getTableRef={getTableRef}/>
            {/*<SummaryCard/>*/}
            <ResultsList tableRef={tableRef}/>
        </main>
    )
}

export default GPSData
