import * as source from './source'



export function createZone (zone) {
    return function (dispatch) {
        dispatch({
            type: 'CREATE_ZONE',
            payload: source.create(zone)
        })
    }
}

export function searchZones (query) {
    return function (dispatch) {
        dispatch({
            type: 'SEARCH_ZONES',
            payload: source.search(query)
        })
    }
}


export function getZoneById (id) {
    return {
            type: 'GET_ZONE_BY_ID',
            payload: source.getZoneById(id)
    }
}

export function updateZone (id,req) {
    return {
        type: 'UPDATE_ZONE',
        payload: source.updateZone(id,req)
    }
}
