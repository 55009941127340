import * as source from './source'



export function createWard (ward) {
    return function (dispatch) {
        dispatch({
            type: 'CREATE_WARD',
            payload: source.create(ward)
        })
    }
}

export function searchWards (query) {
    return function (dispatch) {
        dispatch({
            type: 'SEARCH_WARDS',
            payload: source.search(query)
        })
    }
}


export function getWardById (query) {
    return {
            type: 'GET_WARD_BY_ID',
            payload: source.getWardById(query)
    }
}

export function updateWard (id,req) {
    return {
        type: 'UPDATE_WARD',
        payload: source.updateWard(id,req)
    }
}
