import request from '../request'

export const create = result => {
    return request.post('results/bulk/update', result)
}

export const search = query => {
    return request.post('results/search', query)
}

export const searchResultSummary = query => {
    return request.post('results/search/group', query)
}


export const searchPassRate = query => {
    return request.post('results/search/pass-rate', query)
}


export const searchGpsData = query => {
    return request.post('gps-data/search',query)
}

export const updateGpsData = (id,req) => {
    return request.patch('gps-data/'+id,req)
}
export const getDashboardSummary = () => {
    return request.get('gps-data/summary')
}

export const deleteGpsData = (id) => {
    return request.delete('gps-data/' + id)
}

export const getWardSummary = (gpsDataType,stateId,lgaId) => {
    return request.get(`${gpsDataType}/ward-summary?stateId=${stateId}${lgaId ? '&lgaId=' + lgaId : '' }`)
}
