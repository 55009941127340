import request from '../request'

export const create = lga => {
    return request.post('lgas', lga)
}

export const search = () => {
    return request.get('lgas')
}

export const getLGAById = id => {
    return request.get('lgas/'+ id)
}

export const updateLGA = (id,req) => {
    return request.patch('lgas/'+ id,req)
}
