import React, {Fragment, useCallback, useEffect, useState} from 'react'
import _, {get} from 'lodash'
import {connect, useDispatch} from 'react-redux'

import {
    assignPollingUnit,
    assignStates,
    createUser,
    getUserById,
    searchUsers,
    updateUser
} from '../actions'
import {Input, Select, TKCheckBox} from 'common-modules'
import {searchStates} from "../../State/actions";
import { useParams,useHistory } from "react-router-dom";
import {userRoles} from "../../Utils/utils";
import DataTable from 'react-data-table-component';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faRefresh} from "@fortawesome/free-solid-svg-icons";


const AssignAgents = props => {
    const params = useParams()
    const dispatch = useDispatch()
    const history = useHistory()
    const {  error, user ,users, assignAgentState} = props.userReducer
    const { states} = props.stateReducer
    const [createReq,setCreateReq] = useState({})
    const [searchReq,setSearchReq] = useState({})
    const [filteredUserList, setFilteredUserList] = useState(users)

    const handleOnChange = (value,refer) => {
        searchReq[refer] = value
        setSearchReq({...searchReq})
        if (value)
            setFilteredUserList(_.filter(users, filterUser=> _.startsWith(_.toLower(filterUser.username), _.toLower(value))))
        else
            setFilteredUserList([...users])
    }

    const clearSearch = () =>{
        setSearchReq({});
        setFilteredUserList([...users])
    }


    const assign = () =>{
        dispatch(assignStates(createReq)).then(()=>history.goBack())
    }

    useEffect(()=>{
       setCreateReq({...createReq, stateIdList : _.get(user,'stateIdList',[]), id : user.id})
    },[user])

    useEffect(()=>{
        dispatch(getUserById(params.id))
        // if (users.length < 1)
        //     dispatch(searchUsers({role:"AGENT"}))
    },[])

    // useEffect(()=>{
    //     setFilteredUserList({...users})
    // },[users])

    const columns = [
        {
            name: 'Select All',
            selector: 'name',
        },

        {
            name: 'Code',
            selector: 'code',
        },
    ];

    const handleChange = ({ selectedRows }) => {
        let stateIdList = selectedRows.map(state => state.id);
        setCreateReq({...createReq, stateIdList : stateIdList })

    };

    return (

        <Fragment>
            <div className={params.id ? 'row offset-md-2' : 'row'}>
                <div className="col-md-12 text-center mt-5 " style={{justifyContent:'center',display: 'flex'}}>
                        <div className='col-md-6'>
                            <div className="card bg-light mb-3" style={{maxWidth:'100%'}}>
                                <div className="card-header pb-0"><h3> Assign States to {user.role === "QC" ? "QC" : "supervisor"}</h3></div>
                                    <div className="card-body text-left">
                                        <h5> {user.role === "QC" ? "QC" : "Supervisor"} Name :{user.firstName} {user.lastName}</h5>
                                        <h5> {user.role === "QC" ? "QC" : "Supervisor"} Id :{user.userId}</h5>
                                        {/*<div className="card bg-lightest-blue mb-3" style={{maxWidth:'100%'}}>*/}
                                        {/*    <div className="card-body">*/}
                                        {/*        <div className='row '>*/}
                                        {/*            <div className='col-md-8'>*/}
                                        {/*                <Input*/}
                                        {/*                    refer='username'*/}
                                        {/*                    handleOnChange={handleOnChange}*/}
                                        {/*                    value={get(searchReq, 'username')}*/}
                                        {/*                    placeholder='Username'*/}
                                        {/*                    className="form-control mt-1"/>*/}
                                        {/*            </div>*/}
                                        {/*            <div className="col-md-4">*/}
                                        {/*                <div className={'row'}>*/}
                                        {/*                    <button*/}
                                        {/*                        style={{width:'20%', margin:4}}*/}
                                        {/*                        onClick={clearSearch}*/}
                                        {/*                        className='btn btn-danger btn-block'>*/}
                                        {/*                        <FontAwesomeIcon icon={faRefresh} />*/}
                                        {/*                    </button>*/}
                                        {/*                </div>*/}
                                        {/*            </div>*/}

                                        {/*        </div>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}

                                        <DataTable
                                            columns={columns}
                                            data={states}
                                            selectableRows
                                            persistTableHead
                                            onSelectedRowsChange={handleChange}
                                            selectableRowSelected={(row) => _.get(createReq,'stateIdList',[]).includes(row.id)}
                                        />
                                </div>



                            {
                                params.id && assignAgentState !== 1 &&
                                <div className="row text-center mb-2 " style={{justifyContent:'center',display: 'flex'}}>
                                    <div className="col-md-3 ">
                                        <button
                                            onClick={assign}
                                            className='btn btn-outline-primary btn-block'>
                                            Assign
                                        </button>
                                    </div>
                                </div>
                            }

                            {
                                assignAgentState === 1 &&
                                <div className="row ">
                                    <div className="col-md-12 text-center">
                                        <div className="spinner-border text-primary" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                </div>
                            }

                            {
                                assignAgentState === 3 &&
                                <div className="alert alert-danger alert-dismissible fade show mt-1" role="alert">
                                   Assigned failed
                                    <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                            }
                            </div>
                        </div>
                </div>
            </div>
        </Fragment>
    )
}

export default connect(state => ({
    userReducer : state.userReducer,
    stateReducer : state.stateReducer
}))(AssignAgents)
