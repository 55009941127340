import React, {Fragment, useEffect, useState} from 'react'
import _, {get} from 'lodash'
import {connect, useDispatch} from 'react-redux'

import {assignPollingUnit, getUserById, searchUsers} from '../actions'
import {Input, Select, TKCheckBox} from 'common-modules'
import {useHistory, useParams} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faRefresh} from "@fortawesome/free-solid-svg-icons";
import {userRoles} from "../../Utils/utils";
import DataTable from "react-data-table-component";
import {clearPollingUnits, searchPollingUnits} from "../../PollingUnits/actions";

const AssignPLU = props => {
    const params = useParams()
    const history = useHistory()
    const dispatch = useDispatch()
    const {user , assignPollingUnitState} = props.userReducer

    const { zones } = props.zoneReducer
    const { states } = props.stateReducer
    const { LGAs } = props.lgaReducer
    const { wards, fetchState } = props.wardReducer

    const [createReq,setCreateReq] = useState({wardIdList:[]})
    const [searchReq,setSearchReq] = useState({})
    const [filteredWardList, setFilteredWardList] = useState([])
    const [assignWardList, setAssignWardList] = useState([])
    const [selectedFilteredWardIdList, setSelectedFilteredWardIdList] = useState([])

    const handleOnChange = (value,refer) => {

        searchReq[refer] = value
        setSearchReq({...searchReq})

        console.log(_.filter(wards,ward=> _.get(ward,'lga.state.zoneId') === searchReq.zoneId ))
        console.log(searchReq.zoneId)

        if(searchReq.zoneId && searchReq.stateId && searchReq.lgaId){
            setFilteredWardList(_.filter(wards,{lgaId:searchReq.lgaId}))
        }else if(searchReq.zoneId && searchReq.stateId) {
            setFilteredWardList(_.filter(wards,ward=> _.get(ward,'lga.stateId') === searchReq.stateId ))
        }else if(searchReq.zoneId){
            setFilteredWardList([..._.filter(wards,ward=> _.get(ward,'lga.state.zoneId') === searchReq.zoneId )])
        }else{
            setFilteredWardList([])
        }

    }


    const assign = () =>{
        dispatch(assignPollingUnit(createReq)).then(()=>history.goBack())
    }

    useEffect(()=>{
        setCreateReq({...createReq, wardIdList: _.get(user,'wardIdList',[]), id:user.id})
        setAssignWardList(_.filter(wards, ward=>_.get(user,'wardIdList' , []).includes(ward.id)))
    },[user,wards])

    useEffect(()=>{
        dispatch(getUserById(params.id))
    },[])

    const clearSearch = () =>{
        setSearchReq({});

    }

    useEffect(()=>{
        dispatch(clearPollingUnits())
    },[])


    const columns = [
        {
            name: 'PU name  - Select All',
            selector: 'name',
        }
    ];

    const handleChange = ({ selectedRows }) => {
        let wardIdList = selectedRows.map(ward => ward.id);
        setCreateReq({...createReq, wardIdList : wardIdList })

    };

    const handleFilterChange = ({ selectedRows }) => {
        let selectedWardIdList = selectedRows.map(ward => ward.id);
         setSelectedFilteredWardIdList([...selectedWardIdList])
    };

    const addToAssignList = () => {

        const newAssignList =  [...assignWardList]
        const  wardIdList = createReq.wardIdList
        selectedFilteredWardIdList.forEach(wardId=>{

            const foundWard  = wards.find(ward=>wardId === ward.id)
            console.log(foundWard)
            if(foundWard && !_.find(newAssignList,ward=>ward.id === wardId)){
                console.log(foundWard.name)
                newAssignList.push({id : wardId, name: foundWard.name})
                wardIdList.push(wardId)
            }
        })

        setAssignWardList([...newAssignList])
        setCreateReq({...createReq, wardIdList : wardIdList })
    }

    return (

        <Fragment>
            <div className={params.id ? 'row offset-md-1' : 'row'}>
                <div className="col-md-12 text-center mt-5 " style={{justifyContent:'center',display: 'flex'}}>
                        <div className='col-md-10'>
                            <div className="card bg-light mb-3" style={{maxWidth:'100%'}}>
                                <div className="card-header pb-0"><h3> Assign polling unit to agent</h3></div>
                                    <div className="card-body text-left">
                                        <h5>Agent Name :{user.firstName} {user.lastName}</h5>
                                        <h5>Agent Id :{user.userId}</h5>
                                        <div className="card bg-lightest-blue mb-3" style={{maxWidth:'100%'}}>
                                            <div className="card-body">
                                                <div className='row '>
                                                    <div className='col-md-3'>
                                                        <Select
                                                            refer='zoneId'
                                                            handleOnChange={handleOnChange}
                                                            value={get(searchReq, 'zoneId')}
                                                            placeholder='Zone'
                                                            items = {zones}
                                                            optionValue='name'
                                                            optionKey='id'
                                                            className="form-control mt-1"/>
                                                    </div>
                                                    <div className='col-md-3'>
                                                        <Select
                                                            refer='stateId'
                                                            handleOnChange={handleOnChange}
                                                            value={get(searchReq, 'stateId')}
                                                            placeholder='States'
                                                            items ={_.filter(states,state => _.get(state,'zone.id') === searchReq.zoneId)}
                                                            optionValue='name'
                                                            optionKey='id'
                                                            className="form-control mt-1"/>
                                                    </div>
                                                    <div className='col-md-3'>
                                                        <Select
                                                            refer='lgaId'
                                                            handleOnChange={handleOnChange}
                                                            value={get(searchReq, 'lgaId')}
                                                            placeholder='LGA'
                                                            items ={_.filter(LGAs,lga => _.get(lga,'state.id') === searchReq.stateId)}
                                                            optionValue='name'
                                                            optionKey='id'
                                                            className="form-control mt-1"/>
                                                    </div>
                                                    {/*<div className='col-md-3'>*/}
                                                    {/*    <Select*/}
                                                    {/*        refer='wardId'*/}
                                                    {/*        handleOnChange={handleOnChange}*/}
                                                    {/*        value={get(searchReq, 'wardId')}*/}
                                                    {/*        placeholder='Ward'*/}
                                                    {/*        items ={_.filter(wards,lga => _.get(lga,'lga.id') === searchReq.lgaId)}*/}
                                                    {/*        optionValue='name'*/}
                                                    {/*        optionKey='id'*/}
                                                    {/*        className="form-control mt-1"/>*/}
                                                    {/*</div>*/}
                                                    <div className="col-md-3">
                                                        <div className={'row'}>
                                                            {/*<button*/}
                                                            {/*    style={{width:'50%'}}*/}
                                                            {/*    onClick={onSearch}*/}
                                                            {/*    className='btn btn-success btn-block'>*/}
                                                            {/*    Search*/}
                                                            {/*</button>*/}
                                                            <button
                                                                style={{width:'30%', margin:4}}
                                                                onClick={clearSearch}
                                                                className='btn btn-danger btn-block'>
                                                                <FontAwesomeIcon icon={faRefresh} />
                                                            </button>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <h3>Search results</h3>
                                                <DataTable
                                                    columns={columns}
                                                    data={filteredWardList}
                                                    selectableRows
                                                    persistTableHead
                                                    noDataComponent={
                                                        <div className="alert alert-warning tc" role="alert">
                                                            Please search with query
                                                        </div>
                                                    }
                                                    onSelectedRowsChange={handleFilterChange}
                                                    selectableRowSelected={(row) => selectedFilteredWardIdList.includes(row.id)}
                                                />
                                                <div className="row text-center mb-2 " style={{justifyContent:'center',display: 'flex'}}>
                                                    <div className="col-md-6 ">
                                                        <button
                                                            onClick={addToAssignList}
                                                            className='btn btn-outline-secondary btn-block'>
                                                           Add to Assign List
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-md-6'>
                                                <h3>Assign results</h3>
                                                <DataTable
                                                    columns={columns}
                                                    data={assignWardList}
                                                    selectableRows
                                                    persistTableHead
                                                    onSelectedRowsChange={handleChange}
                                                    selectableRowSelected={(row) => _.get(createReq,'wardIdList',[]).includes(row.id)}
                                                />
                                                {
                                                    params.id && assignPollingUnitState !== 1 &&
                                                    <div className="row text-center mb-2 " style={{justifyContent:'center',display: 'flex'}}>
                                                        <div className="col-md-3 ">
                                                            <button
                                                                onClick={assign}
                                                                className='btn btn-primary btn-block'>
                                                                Assign
                                                            </button>
                                                        </div>
                                                    </div>
                                                }

                                            </div>
                                        </div>


                                </div>




                            {
                                (assignPollingUnitState === 1 || fetchState === 1) &&
                                <div className="row ">
                                    <div className="col-md-12 text-center">
                                        <div className="spinner-border text-primary" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                </div>
                            }

                            {
                                assignPollingUnitState === 3 &&
                                <div className="alert alert-danger alert-dismissible fade show mt-1" role="alert">
                                   Assigned failed
                                    <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                            }
                            </div>
                        </div>
                </div>
            </div>
        </Fragment>
    )
}

export default connect(state => ({
    userReducer : state.userReducer,
    pollingUnitReducer : state.pollingUnitReducer,
    zoneReducer:state.zoneReducer,
    stateReducer:state.stateReducer,
    lgaReducer:state.lgaReducer,
    wardReducer:state.wardReducer,
}))(AssignPLU)
