import request from '../request'

export const create = zone => {
    return request.post('zones', zone)
}

export const search = () => {
    return request.get('zones')
}

export const getZoneById = id => {
    return request.get('zones/'+ id)
}

export const updateZone = (id,req) => {
    return request.patch('zones/'+ id,req)
}
