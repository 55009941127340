import { keyBy ,get} from 'lodash'

const initialState = {
    ward: {},
    createState: 0,
    wards: [],
    fetchState: 0,

    error: {}
}

const reducer = (state = initialState, action) => {
    switch (action.type) {

        case 'CREATE_WARD_PENDING':
            return { ...state, createState: 1 }
        case 'CREATE_WARD_FULFILLED':
            const ward = action.payload.data
            return {
                ...state,
                createState: 2,
                ward: {},
                wards: [...state.wards,ward]
            }
        case 'CREATE_WARD_REJECTED':
            return {
                ...state,
                createState: 3,
                error: { ...state.error, create: get(action.payload,['response','data']) }
            }

        case 'GET_WARDS_PENDING':
            return { ...state, fetchState: 1 }
        case 'GET_WARDS_FULFILLED':

            return { ...state, fetchState: 2, wards:action.payload.data}
        case 'GET_WARDS_REJECTED':
            return {
                ...state,
                fetchState: 3,
                error: { ...state.error, fetch: get(action.payload,['response','data']) }
            }

        case 'SEARCH_WARDS_PENDING':
            return { ...state, fetchState: 1 }
        case 'SEARCH_WARDS_FULFILLED':
            console.log("action.payload.data",action.payload.data)
            return { ...state, fetchState: 2, wards: action.payload.data}
        case 'SEARCH_WARDS_REJECTED':
            return {
                ...state,
                fetchState: 3,
                error: { ...state.error, fetch: get(action.payload,['response','data']) }
            }

        default:
            return state
    }
}

export default reducer
