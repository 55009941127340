import React, {Fragment, useEffect, useState} from 'react'
import _, {get} from 'lodash'
import {connect, useDispatch} from 'react-redux'
import {useHistory, useParams} from "react-router-dom";
import {createLGA, getLGAById, searchLGAs, updateLGA} from '../actions'
import {Input, Select} from 'common-modules'
import dataSource from "../../Main/dataSource";

const CreateLGA = props => {
    const dispatch = useDispatch()
    const params = useParams()
    const history = useHistory()
    const {error } = props.lgaReducer
    const {states} = props.stateReducer
    const {zones} = props.zoneReducer
    const [createReq,setCreateReq] = useState({})

    const handleOnChange = (value,refer) => {
        createReq[refer] = value
        setCreateReq({...createReq})
    }
    const addLGA = () => {
        dispatch(createLGA(createReq))
    }

    useEffect(()=>{
        if(params.id)
            dispatch(getLGAById(params.id)).then(response=>{
                setCreateReq({...response.value.data, zoneId: _.get(response.value.data,'state.zone.id')})
            })
    },[])

    const update = () =>{
        dispatch(updateLGA(params.id,createReq)).then(()=>{
            history.push('/lgas')
            dispatch(searchLGAs())
        })
    }


    console.log(states)
    console.log(zones)


    return (

        <Fragment>
            <div className="row">
                <div className="col-md-12 text-center mt-5 " style={{justifyContent:'center',display: 'flex'}}>
                        <div className='col-md-6'>
                            <div className="card bg-light mb-3" style={{maxWidth:'100%'}}>
                                <div className="card-header pb-0"><h3>{params.id?"Update LGA":"Add LGAs"}</h3></div>
                                <div className="card-body">
                                    <div className='mt-2'>
                                        <Input
                                            refer='name'
                                            handleOnChange={handleOnChange}
                                            value={get(createReq, 'name')}
                                            placeholder='Name'
                                            className="form-control mt-1"/>
                                    </div>
                                    <div className='mt-2'>
                                        <Input
                                            refer='code'
                                            handleOnChange={handleOnChange}
                                            value={get(createReq, 'code')}
                                            placeholder='Code'
                                            className="form-control mt-1"/>
                                    </div>
                                    <div className='mt-2'>
                                        <Select
                                            refer='zoneId'
                                            handleOnChange={handleOnChange}
                                            value={get(createReq, 'zoneId')}
                                            placeholder='Zone'
                                            items ={zones}
                                            optionValue='name'
                                            optionKey='id'
                                            className="form-control mt-1"/>
                                    </div>
                                    <div className='mt-2'>
                                        <Select
                                            refer='stateId'
                                            handleOnChange={handleOnChange}
                                            value={get(createReq, 'stateId')}
                                            placeholder='State'
                                            items ={_.filter(states,state => _.get(state,'zone.id','') === createReq.zoneId)}
                                            optionValue='name'
                                            optionKey='id'
                                            className="form-control mt-1"/>
                                    </div>
                                </div>

                            {
                                !params.id && createLGA !== 1 &&
                                <div className="row text-center mb-2 " style={{justifyContent:'center',display: 'flex'}}>
                                    <div className="col-md-3 ">
                                        <button
                                            onClick={addLGA}
                                            className='btn btn-outline-success btn-block'>
                                            Create
                                        </button>
                                    </div>
                                </div>
                            }

                            {   params.id && createLGA !== 1 &&
                                <div className="row text-center mb-2 " style={{justifyContent:'center',display: 'flex'}}>
                                    <div className="col-md-3 ">
                                        <button
                                            onClick={update}
                                            className='btn btn-outline-primary btn-block'>
                                            Update
                                        </button>
                                    </div>
                                </div>
                            }

                            {
                                createLGA === 1 &&
                                <div className="row ">
                                    <div className="col-md-12 text-center">
                                        <div className="spinner-border text-primary" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                </div>
                            }

                            {
                                createLGA === 3 &&
                                <div className="alert alert-danger alert-dismissible fade show mt-1" role="alert">
                                    {get(error, 'create')}
                                    <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                            }
                            </div>
                        </div>
                </div>
            </div>
        </Fragment>
    )
}

export default connect(lga => ({
    lgaReducer : lga.lgaReducer,
    zoneReducer : lga.zoneReducer,
    stateReducer : lga.stateReducer
}))(CreateLGA)
