import React from 'react'

import '../../Main/static/css/dashboard.css'
import CreatePollingUnit from "./CreatePollingUnit";
import PollingUnitsList from "./PollingUnitsList";

const AddPollingUnits = () => {
    return (
        <main role="main" className="col-md-9 ml-sm-auto col-lg-10 px-md-4">
            <CreatePollingUnit/>
            <PollingUnitsList/>
        </main>
    )
}

export default AddPollingUnits
