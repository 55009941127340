import React, {Fragment, useEffect, useRef} from 'react'

import '../../Main/static/css/dashboard.css'

import ResultsList from "./ResultsList";
import SearchDashboard from "./SearchDashboard";
import SummaryCard from "./SummaryCard";
import {Bar, CartesianGrid, Cell, Legend, Pie, PieChart, BarChart, Tooltip, XAxis, YAxis} from "recharts";
import {connect, useDispatch} from "react-redux";
import {getDashboardSummary} from "../actions";
import _ from "lodash";

const Dashboard = props => {
    const tableRef = useRef(null);
    const dispatch = useDispatch()
    const { dashboardSummary, error } = props.resultsReducer

    const zoneSummaryList = _.get(dashboardSummary,'zone',[])
    const stateSummaryList = _.get(dashboardSummary,'state',[])
    const lgaSummaryList = _.get(dashboardSummary,'lga',[])
    const wardSummaryList = _.get(dashboardSummary,'ward',[])
    const pollingUnitSummaryList = _.get(dashboardSummary,'pollingUnit',[])
    const pollingUnitOverViewList = _.get(dashboardSummary,'pollingUnitOverView',[])
    const statesOverViewList = _.get(dashboardSummary,'statesOverView',[])


    useEffect(()=>{
        dispatch(getDashboardSummary())
    },[])

    const COLORS = [  '#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#F283D2','#6F8642','#9284E4', '#2F8042', '#728392', '#224367','#938942','#E28444'];

    const RADIAN = Math.PI / 180;
    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        return (
            <text x={x} y={y} fill="white" fontSize='14'>
                {`${(percent * 100).toFixed(0)}%`}
            </text>
        );
    }


    return (
        <main role="main" className="col-md-9 ml-sm-auto col-lg-10 px-md-4">
        <Fragment>
        <div className='row mt-4'>
                <div className='col-md-6'>
                    <h3 className=''>Zone summary</h3>
                    <PieChart width={300} height={300}>
                        <Pie
                            data={zoneSummaryList}
                            cx="50%"
                            cy="50%"
                            labelLine={false}
                            label={renderCustomizedLabel}
                            outerRadius={120}
                            fill="#8884d8"
                            dataKey="count"
                        >
                            {zoneSummaryList.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                            ))}
                        </Pie>
                        <Tooltip label='name'/>
                    </PieChart>
                </div>
            <div className='col-md-6'>
                <h3 className=''>State summary</h3>
                <PieChart width={300} height={300}>
                    <Pie
                        data={stateSummaryList}
                        cx="50%"
                        cy="50%"
                        labelLine={false}
                        label={renderCustomizedLabel}
                        outerRadius={120}
                        fill="#8884d8"
                        dataKey="count">
                        {stateSummaryList.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                        ))}
                    </Pie>
                    <Tooltip/>
                </PieChart>
            </div>
        </div>
        {/*    <div className='row'>*/}
        {/*<div className='col-md-6'>*/}
        {/*    <h3 className=''>LAG summary</h3>*/}
        {/*    <BarChart*/}
        {/*        width={500}*/}
        {/*        height={300}*/}
        {/*        data={lgaSummaryList}*/}
        {/*        margin={{*/}
        {/*            top: 5,*/}
        {/*            right: 30,*/}
        {/*            left: 20,*/}
        {/*            bottom: 5,*/}
        {/*        }}*/}
        {/*        dataKey='count'*/}
        {/*        barSize={12}*/}
        {/*    >*/}
        {/*        <XAxis dataKey="name" scale="point" padding={{ left: 10, right: 10 }} />*/}
        {/*        <YAxis  />*/}
        {/*        <Tooltip />*/}
        {/*        <Legend />*/}
        {/*        <CartesianGrid strokeDasharray="3 3" />*/}
        {/*        <Bar dataKey="count" fill="#8884d8" background={{ fill: '#eee' }} />*/}
        {/*    </BarChart>*/}

        {/*</div>*/}
            {/*<div className='col-md-6'>*/}
            {/*    <h3 className=''>Ward summary</h3>*/}
            {/*    <BarChart*/}
            {/*        width={500}*/}
            {/*        height={300}*/}
            {/*        data={wardSummaryList}*/}
            {/*        margin={{*/}
            {/*            top: 5,*/}
            {/*            right: 30,*/}
            {/*            left: 20,*/}
            {/*            bottom: 5,*/}
            {/*        }}*/}
            {/*        dataKey='count'*/}
            {/*        barSize={12}*/}
            {/*    >*/}
            {/*        <XAxis dataKey="name" scale="point" padding={{ left: 10, right: 10 }} />*/}
            {/*        <YAxis  />*/}
            {/*        <Tooltip />*/}
            {/*        <Legend />*/}
            {/*        <CartesianGrid strokeDasharray="3 3" />*/}
            {/*        <Bar dataKey="count" fill="#FF8042" background={{ fill: '#eee' }} />*/}
            {/*    </BarChart>*/}

            {/*</div>*/}
            {/*</div>*/}
            {/*<div className='col-md-12'>*/}
            {/*    <h3 className=''>PU summary</h3>*/}
            {/*    <BarChart*/}
            {/*        width={1000}*/}
            {/*        height={300}*/}
            {/*        data={pollingUnitSummaryList}*/}
            {/*        margin={{*/}
            {/*            top: 5,*/}
            {/*            right: 30,*/}
            {/*            left: 20,*/}
            {/*            bottom: 5,*/}
            {/*        }}*/}
            {/*        dataKey='count'*/}
            {/*        barSize={12}*/}
            {/*    >*/}
            {/*        <XAxis dataKey="name" scale="point" padding={{ left: 10, right: 10 }} />*/}
            {/*        <YAxis  />*/}
            {/*        <Tooltip />*/}
            {/*        <CartesianGrid strokeDasharray="3 3" />*/}
            {/*        <Bar dataKey='count' fill="#8884d8" background={{ fill: '#eee' }} >*/}
            {/*            {pollingUnitSummaryList.map((entry, index) => (*/}
            {/*                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />*/}
            {/*            ))}*/}
            {/*        </Bar>*/}
            {/*    </BarChart>*/}

            {/*</div>*/}
            <h3 className='ml-3'>PU Overview</h3>
            <div className='row'>

                <div className='col-md-6'>
                    <PieChart width={300} height={300}>
                        <Pie
                            data={pollingUnitOverViewList}
                            cx="50%"
                            cy="50%"
                            labelLine={false}
                            label={renderCustomizedLabel}
                            outerRadius={120}
                            fill="#8884d8"
                            dataKey="count"
                        >
                            {zoneSummaryList.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                            ))}
                        </Pie>
                        <Tooltip label='name'/>
                    </PieChart>
                </div>
                <div className='col-md-6 mt-5'>
                    <p> Total No of registered Pu : {_.get(_.find(pollingUnitOverViewList,{id: "COLLECTED"}),'count',0) + _.get(_.find(pollingUnitOverViewList,{id: "NOT_COLLECTED"}),'count',0)}</p>
                    <p> Data collected : {_.get(_.find(pollingUnitOverViewList,{id: "COLLECTED"}),'count',0)} </p>
                    <p> Data <i className='red'> not</i> collected :{_.get(_.find(pollingUnitOverViewList,{id: "NOT_COLLECTED"}),'count',0)} </p>
                </div>
            </div>
            <div className='row'>
                <div className='col-md-12'>
                                <table width='80%' border={1}>
                                    <tr bgcolor='black' style={{color:'white'}}>
                                        <th width="40%">State</th>
                                        <th width="20%">Total Polling Units</th>
                                        <th width="20%">Captured Polling Units</th>
                                        <th width="20%">Percentage</th>
                                    </tr>
                                    {statesOverViewList.map(stateData=>{
                                       return   <tr>
                                                    <th width="40%">{stateData.name}</th>
                                                    <th width="20%">{stateData.totalPU}</th>
                                                    <th width="20%">{stateData.pucaptured}</th>
                                                    <th width="20%">{(stateData.pucaptured/stateData.totalPU*100).toFixed(2)} %</th>
                                                </tr>
                                        })
                                    }
                                </table>
                </div>
            </div>

        </Fragment>
        </main>
    )
}

export default connect(state => ({
    resultsReducer: state.resultsReducer,
}))(Dashboard)
