import request from '../request'

export const create = user => {
    return request.post('users', user)
}

export const search = (query) => {
    console.log("query",query)
    let params = `?`
    if(query.role)
        params = `${params}&role=${query.role}`
    if(query.username)
        params = `${params}&username=${query.username}`
    return request.get(`users${params}`)
}

export const getUserById = id => {
    return request.get('users/'+ id)
}

export const updateUser = (id,req) => {
    return request.patch('users/'+ id,req)
}

export const assignPollingUnit = (req) => {
    return request.post('users/assign-pu',req)
}

export const assignStates = (req) => {
    return request.post('users/assign-states',req)
}

export const deleteUser = (id) => {
    return request.delete('users/'+ id)
}
