import React, {Fragment, useEffect, useRef, useState} from 'react'
import _, {get} from 'lodash'
import {connect, useDispatch} from 'react-redux'

import {
    createResult,
    isDownloading,
    searchGpsData,
    searchPassRate,
    searchResults,
    searchResultSummary,
    uploadFile
} from '../actions'
import {Auth, Input, Select} from 'common-modules'
import dataSource from "../../Main/dataSource";
import resultsReducer from "../reducer";
import {getUsersRole, isAdminUser} from "../../Utils/utils";
import {faDownload, faEye, faRefresh, faUpload} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import TableToExcel from "@stanlystark/table-to-excel";

const SearchResults = props => {
    const dispatch = useDispatch()
    const { createState, error, fileUploadState,isDownloadingState } = props.resultsReducer2
    const { zones } = props.zoneReducer
    const { states } = props.stateReducer
    const { LGAs } = props.lgaReducer
    const { wards } = props.wardReducer
    const { user } = props.userReducer
    const initSearchReq =getUsersRole() === "COORDINATOR" ? {} :
        {stateIdList: Auth.getUser().stateIdList}
    const [searchReq,setSearchReq] = useState(initSearchReq)
    const [file,setFile] = useState(null)
    const tableRef = useRef(null);


    const handleOnChange = (value,refer) => {
        console.log(refer,value)
        searchReq[refer] = value
        setSearchReq({...searchReq})
    }
    const addResult = () => {
        dispatch(searchGpsData(searchReq))

    }

    useEffect(()=>{
        //dispatch(searchGpsData(searchReq))
    },[])

    const clearSearch = () =>{
        setSearchReq({...initSearchReq});
        dispatch(searchGpsData(initSearchReq))
    }

    const  onDownload = () => {
        dispatch(isDownloading(true))
    }
    const onChooseFile = (event) => {
        if(event.target.files && event.target.files[0]){
            setFile(event.target.files[0])
        }
    }

    const onUpload = () =>{
        let data = new FormData()
        data.append('excelFile',file)
        dispatch(uploadFile(data))
    }

    useEffect(()=>{
        if(isDownloadingState){
            TableToExcel.convert(document.getElementById("gps-data-table"), {
                name: "GPS_data_table.xlsx",
                sheet: {
                    name: "GpsData"
                }
            });
            dispatch(isDownloading(false))
        }
    },[isDownloadingState])

    return (

        <Fragment>
            <div className="row">
                <div className="col-md-12 text-center mt-5 " style={{justifyContent:'center',display: 'flex'}}>
                        <div className='col-md-12'>
                            <div className="card bg-lightest-blue mb-3" style={{maxWidth:'100%'}}>
                                <div className="card-body">
                                    <h5 className='text-left'>Search Results</h5>
                                    <div className='row'>
                                        {getUsersRole() === "COORDINATOR" &&
                                            <div className='col-md-2 mt-2'>
                                                <Select
                                                    refer='zoneId'
                                                    handleOnChange={handleOnChange}
                                                    value={get(searchReq, 'zoneId')}
                                                    placeholder='Zone'
                                                    items={zones}
                                                    optionValue='name'
                                                    optionKey='id'
                                                    className="form-control mt-1"/>
                                            </div>
                                        }
                                        <div className='col-md-2 mt-2'>
                                            <Select
                                                refer='stateId'
                                                handleOnChange={handleOnChange}
                                                value={get(searchReq, 'stateId')}
                                                placeholder='State'
                                                items ={getUsersRole() === "COORDINATOR" ?
                                                    _.filter(states,state => _.get(state,'zone.id') === searchReq.zoneId) :
                                                    _.filter(states,state => _.get(Auth.getUser(),'stateIdList', []).includes(state.id))}

                                                optionValue='name'
                                                optionKey='id'
                                                className="form-control mt-1"/>
                                        </div>

                                        <div className='col-md-3 mt-2'>
                                            <Select
                                                refer='lgaId'
                                                handleOnChange={handleOnChange}
                                                value={get(searchReq, 'lgaId')}
                                                placeholder='LGA'
                                                items ={_.filter(LGAs,lga => _.get(lga,'state.id') === searchReq.stateId)}
                                                optionValue='name'
                                                optionKey='id'
                                                className="form-control mt-1"/>
                                        </div>
                                        <div className='col-md-3 mt-2'>
                                            <Select
                                                refer='wardId'
                                                handleOnChange={handleOnChange}
                                                value={get(searchReq, 'wardId')}
                                                placeholder='Wards'
                                                items ={_.filter(wards,ward => _.get(ward,'lga.id') === searchReq.lgaId)}
                                                optionValue='name'
                                                optionKey='id'
                                                className="form-control mt-1"/>
                                        </div>
                                        <div className="col-md-2 mt-2">
                                            <div className={'row'}>
                                                <button
                                                    style={{width:'50%'}}
                                                    onClick={addResult}
                                                    className='btn btn-success btn-block'>
                                                    Search
                                                </button>
                                                <button
                                                    style={{width:'20%', margin:4}}
                                                    onClick={clearSearch}
                                                    className='btn btn-danger btn-block'>
                                                    <FontAwesomeIcon icon={faRefresh} />
                                                </button>
                                                {isAdminUser() &&
                                                    <button
                                                        style={{width: '20%', margin: 4}}
                                                        onClick={onDownload}
                                                        className='btn btn-secondary btn-block'>
                                                        <FontAwesomeIcon icon={faDownload}/>
                                                    </button>
                                                }
                                            </div>
                                        </div>

                                    </div>
                                    {isAdminUser() &&

                                    <div className='row mt-2'>
                                        <div className='col-md-2 text-left'>
                                        <p className='text-left'>Upload excel file</p>
                                        </div>
                                        <div className='col-md-3 text-left'>

                                            <input onChange={e=>onChooseFile(e)} type="file" id="myfile" name="myfile"/>
                                            {fileUploadState === 2 &&
                                                <div className="alert alert-success alert-dismissible fade show mt-1" role="alert">
                                                   File uploaded successfully
                                                    <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                                        <span aria-hidden="true">&times;</span>
                                                    </button>
                                                </div>
                                            }

                                            {fileUploadState === 3 &&
                                                <div className="alert alert-danger alert-dismissible fade show mt-1" role="alert">
                                                    File upload failed try again later
                                                    <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                                        <span aria-hidden="true">&times;</span>
                                                    </button>
                                                </div>
                                            }
                                        </div>
                                        <div className='col-md-2'>
                                            <button
                                                disabled={!file || fileUploadState === 1}
                                                style={{width: '40%',}}
                                                onClick={onUpload}
                                                className='btn btn-secondary btn-block'>
                                                <FontAwesomeIcon icon={faUpload}/></button>
                                        </div>
                                    </div>
                                        }
                                </div>

                            {
                                (createState === 1 || fileUploadState === 1) &&
                                <div className="row ">
                                    <div className="col-md-12 text-center">
                                        <div className="spinner-border text-primary" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                </div>
                            }

                            {
                                createState === 3 &&
                                <div className="alert alert-danger alert-dismissible fade show mt-1" role="alert">
                                    {get(error, 'create')}
                                    <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                            }
                            </div>
                        </div>
                </div>
            </div>
        </Fragment>
    )
}

export default connect(state => ({
    resultsReducer2:state.resultsReducer2,
    zoneReducer:state.zoneReducer,
    stateReducer:state.stateReducer,
    lgaReducer:state.lgaReducer,
    wardReducer:state.wardReducer,
    userReducer:state.userReducer,
}))(SearchResults)
