import {get, keyBy} from 'lodash'

const initialState = {
    countryState: {},
    createStateState: 0,
    states: [],
    fetchState: 0,

    error: {}
}

const reducer = (state = initialState, action) => {
    switch (action.type) {

        case 'CREATE_STATE_PENDING':
            return { ...state, createStateState: 1 }
        case 'CREATE_STATE_FULFILLED':
            const countryState = action.payload.data
            return {
                ...state,
                createStateState: 2,
                countryState: {},
                states:  [ ...state.states, countryState ]
            }
        case 'CREATE_STATE_REJECTED':
            return {
                ...state,
                createStateState: 3,
                // error: { ...state.error, create: get(action.payload,['response','data']) }
            }

        case 'GET_STATES_PENDING':
            return { ...state, fetchState: 1 }
        case 'GET_STATES_FULFILLED':
            return {...state, fetchState: 2, states: action.payload.data}
        case 'GET_STATES_REJECTED':
            return {
                ...state,
                fetchState: 3,
                error: { ...state.error, fetch: get(action.payload,['response','data'])}
            }

        case 'SEARCH_STATES_PENDING':
            return { ...state, fetchState: 1 }
        case 'SEARCH_STATES_FULFILLED':
            return { ...state, fetchState: 2, states: action.payload.data}
        case 'SEARCH_STATES_REJECTED':
            return {
                ...state,
                fetchState: 3,
                error: { ...state.error, fetch: get(action.payload,['response','data'])}
            }

        default:
            return state
    }
}

export default reducer
