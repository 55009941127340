import React, {Fragment, useEffect} from 'react'
import _, {get} from 'lodash'
import {connect, useDispatch} from 'react-redux'
import { useHistory } from "react-router-dom";
import {searchLGAs} from '../actions'
import {searchZones} from "../../Zone/actions";
import {searchStates} from "../../State/actions";

const LGAsList = props => {
    const dispatch = useDispatch()
    const history = useHistory()
    const { fetchLGA, error, LGAs } = props.lgaReducer

    useEffect(() => {
        // dispatch(searchLGAs())
        // dispatch(searchZones())
        // dispatch(searchStates())
    }, [])


    return (
        <Fragment>
            {
                fetchLGA === 2 &&
                <div className="my-3 p-3 bg-white rounded shadow-sm">
                    <div className='row'>
                    {
                        LGAs.map(lga => {
                            return (
                                <div className='col-md-3'>
                                    <div className="card">
                                        <div className="card-body">
                                            <h5 className="card-title">{lga.name}</h5>
                                           <div> State: <span className='badge badge-primary'>{_.get(lga,'state.name')}</span></div>
                                           <div> Zone: <span className='badge badge-primary'>{_.get(lga,'state.zone.name')}</span></div>
                                        </div>
                                        <div className='card-footer text-center'>
                                            <button type="button " className="btn btn-warning" onClick={e=>history.push('/lgas/'+lga.id)} >
                                                Edit
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                    </div>
                </div>
            }

            {
                fetchLGA === 1 &&
                <div className="row ">
                    <div className="col-md-12 text-center">
                        <div className="spinner-border text-primary" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                </div>
            }

            {
                fetchLGA === 3 &&
                <div className="alert alert-danger alert-dismissible fade show mt-1" role="alert">
                    {get(error, 'create')}
                    <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            }
            {fetchLGA === 2 && LGAs.length < 1 &&
                <div className="alert alert-warning" role="alert">
                    No lgas found
                </div>
            }
        </Fragment>
    )
}

export default connect(lga => ({
    lgaReducer:lga.lgaReducer
}))(LGAsList)
