    import React, {useEffect} from 'react'
import { NavLink } from 'react-router-dom'
import FeatherIcon from 'feather-icons-react'
import {getUsersRole, isAdminUser} from "../../Utils/utils";
import {useDispatch} from "react-redux";
    import {searchZones} from "../../Zone/actions";
    import {searchStates} from "../../State/actions";
    import {searchLGAs} from "../../LGA/actions";
    import {searchWards} from "../../Ward/actions";
    import {searchPollingUnits} from "../../PollingUnits/actions";

const SideMenu = () => {

    const dispatch = useDispatch();

    useEffect(()=>{
        dispatch(searchZones())
        dispatch(searchStates())
        dispatch(searchLGAs())
        dispatch(searchWards())
        // dispatch(searchPollingUnits({page:0, size: 100}))
    },[])
    return (
        <nav id='sidebarMenu' className='col-md-3 col-lg-2 d-md-block sidebar collapse' style={{background: '#030b27'}}>
            <div className='sidebar-sticky pt-3'>
                <ul className='nav flex-column'>
                    {getUsersRole() !== "SUPERVISOR" &&
                        <NavLink className='nav-link' to='/dashboard' style={{'color': '#ffffff'}}>
                            <FeatherIcon icon='home'/>
                            Dashboard <span className='sr-only'>(current)</span>
                        </NavLink>
                    }
                    {getUsersRole() === "COORDINATOR" &&
                        <NavLink className='nav-link' to='/dashboard2' style={{'color': '#ffffff'}}>
                            <FeatherIcon icon='home'/>
                            Dashboard 2 <span className='sr-only'>(current)</span>
                        </NavLink>
                    }
                    <NavLink className='nav-link' to='/gps-data' style={{'color': '#ffffff'}}>
                        <FeatherIcon icon='map'/>
                        GPS Data <span className='sr-only'></span>
                    </NavLink>
                    {getUsersRole() === "COORDINATOR" &&
                        <NavLink className='nav-link' to='/gps-data2' style={{'color': '#ffffff'}}>
                            <FeatherIcon icon='map'/>
                            GPS Data 2 <span className='sr-only'></span>
                        </NavLink>
                    }
                    {getUsersRole() !== "SUPERVISOR" &&
                        <NavLink className='nav-link' to='/report' style={{'color': '#ffffff'}}>
                            <FeatherIcon icon='list'/>
                            Report <span className='sr-only'>(current)</span>
                        </NavLink>
                    }
                    {getUsersRole() === "COORDINATOR" &&
                        <NavLink className='nav-link' to='/report2' style={{'color': '#ffffff'}}>
                            <FeatherIcon icon='list'/>
                            Report2 <span className='sr-only'>(current)</span>
                        </NavLink>
                    }
                </ul>

                {isAdminUser() &&
                    <div>
                        <h6 className='sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted'>
                            <span style={{'color': '#ffffff'}}>ADD DATA</span>
                        </h6>


                        <NavLink className='nav-link' to='/users' style={{'color': '#ffffff'}}>
                            <FeatherIcon icon='plus-circle'/>
                            Add Users
                        </NavLink>


                        <NavLink className='nav-link' to='/zones' style={{'color': '#ffffff'}}>
                            <FeatherIcon icon='plus-circle'/>
                            Add Zones
                        </NavLink>



                    <NavLink className='nav-link' to='/states' style={{'color': '#ffffff'}}>
                        <FeatherIcon icon='plus-circle'/>
                        Add States
                    </NavLink>

                    <NavLink className='nav-link' to='/lgas' style={{'color': '#ffffff'}}>
                        <FeatherIcon icon='plus-circle'/>
                        Add LGAs
                    </NavLink>

                        <NavLink className='nav-link' to='/wards' style={{'color': '#ffffff'}}>
                            <FeatherIcon icon='plus-circle'/>
                            Add Wards
                        </NavLink>

                        <NavLink className='nav-link' to='/pollingUnits' style={{'color': '#ffffff'}}>
                            <FeatherIcon icon='plus-circle'/>
                            Add Polling units
                        </NavLink>
                </div>
                }
            </div>
        </nav>
    )
}

export default SideMenu
