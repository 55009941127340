import * as source from './source'



export function createPollingUnit (pollingUnit) {
    return function (dispatch) {
        dispatch({
            type: 'CREATE_POLLING_UNIT',
            payload: source.create(pollingUnit)
        })
    }
}

export function searchPollingUnits (query) {
    return function (dispatch) {
        dispatch({
            type: 'SEARCH_POLLING_UNITS',
            payload: source.search(query)
        })
    }
}


export function getPollingUnitById (query) {
    return {
            type: 'GET_POLLING_UNIT_BY_ID',
            payload: source.getPollingUnitById(query)
    }
}

export function updatePollingUnit (id,req) {
    return {
        type: 'UPDATE_POLLING_UNIT',
        payload: source.updatePollingUnit(id,req)
    }
}

export function clearPollingUnits () {
    return {
        type: 'CLEAR_POLLING_UNITS',
        payload: true
    }
}
