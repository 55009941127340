import * as source from './source'



export function createUser (user) {
    return function (dispatch) {
        dispatch({
            type: 'CREATE_USER',
            payload: source.create(user)
        })
    }
}

export function searchUsers (query) {
    return function (dispatch) {
        dispatch({
            type: 'SEARCH_USERS',
            payload: source.search(query)
        })
    }
}


export function getUserById (id) {
    return {
            type: 'GET_USER_BY_ID',
            payload: source.getUserById(id)
    }
}

export function updateUser (id,req) {
    return {
        type: 'UPDATE_USER',
        payload: source.updateUser(id,req)
    }
}

export function assignPollingUnit (req) {
    return {
        type: 'ASSIGN_POLLING_UNIT',
        payload: source.assignPollingUnit(req)
    }
}

export function assignStates (req) {
    return {
        type: 'ASSIGN_STATES',
        payload: source.assignStates(req)
    }
}

export function deleteUser (id) {
    return function (dispatch) {
        dispatch({
        type: 'DELETE_USER',
        payload: source.deleteUser(id)
        })
    }
}
