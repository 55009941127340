import * as source from './source'



export function createState (req) {
    return function (dispatch) {
        dispatch({
            type: 'CREATE_STATE',
            payload: source.create(req)
        })
    }
}

export function searchStates () {
    return function (dispatch) {
        dispatch({
            type: 'SEARCH_STATES',
            payload: source.search()
        })
    }
}

export function getStateById (query) {
    return {
        type: 'GET_STATE_BY_ID',
        payload: source.getStateById(query)
    }
}

export function updateState (id,req) {
    return {
        type: 'UPDATE_STATE',
        payload: source.updateState(id,req)
    }
}


