import React from 'react'

import '../../Main/static/css/dashboard.css'
import CreateState from "./CreateState";
import StatesList from "./StatesList";

const AddStates = () => {
    return (
        <main role="main" className="col-md-9 ml-sm-auto col-lg-10 px-md-4">
            <CreateState/>
            <StatesList/>
        </main>
    )
}

export default AddStates
