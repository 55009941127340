import axios from 'axios'
import { Auth } from 'common-modules'

const accessToken = Auth.getToken()

const url = {
 //   base: 'http://109.169.87.87:9050/api/v1/service/' //test
    base: 'http://109.169.87.87:9050/api/v1/service/'
}

let getAxiosConfig = (isFormDataConfig) => {
    return {
        headers: {
            'Content-Type': isFormDataConfig ? 'multipart/form-data' : 'application/json;charset=UTF-8' ,
            'Access-Control-Allow-Origin': '*',
            'token': Auth.getToken()
        }
    }
}


const request = {
    get: (path) => axios.get(url.base + path, getAxiosConfig()),
    delete: (path) => axios.delete(url.base + path, getAxiosConfig()),
    post: (path, data) => axios.post(url.base + path, data, getAxiosConfig()),
    patch: (path, data) => axios.patch(url.base + path, data, getAxiosConfig()),
    postFormData: (path, data) => axios.post(`${url.base + path}`, data,  getAxiosConfig(true))
}

export default request
