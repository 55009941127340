import React from 'react'

import '../../Main/static/css/dashboard.css'
import CreateLGA from "./CreateLGA";
import LGAsList from "./LGAsList";

const AddLGAs = () => {
    return (
        <main role="main" className="col-md-9 ml-sm-auto col-lg-10 px-md-4">
            <CreateLGA/>
            <LGAsList/>
        </main>
    )
}

export default AddLGAs
