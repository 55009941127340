import _, { keyBy } from 'lodash'

const initialState = {
    result: {},
    createState: 0,
    results: {},
    fetchResultState: 0,
    error: {},
    summary:{},
    passRate:{},
    gpsDataState:0,
    gpsDataList:[],
    updateState:0,
    summaryState:0,
    deleteState:0,
    dashboardSummary:{},
    fileUploadState:0,
    isDownloadingState:false
}

const reducer = (state = initialState, action) => {
    switch (action.type) {

        case 'CREATE_RESULT_PENDING':
            return { ...state, createState: 1 }
        case 'CREATE_RESULT_FULFILLED':
            const result = action.payload.data
            return {
                ...state,
                createState: 2,
                result: {},
                results: keyBy(action.payload.data, 'id')
            }
        case 'CREATE_RESULT_REJECTED':
            return {
                ...state,
                createState: 3,
                error: { ...state.error, create: action.payload.response.data }
            }

        case 'GET_RESULTS_PENDING':
            return { ...state, fetchResultState: 1 }
        case 'GET_RESULTS_FULFILLED':
            return { ...state, fetchResultState: 2, results: keyBy(action.payload.data, 'id')}
        case 'GET_RESULTS_REJECTED':
            return {
                ...state,
                fetchResultState: 3,
                error: { ...state.error, fetch: action.payload.response.data }
            }

        case 'SEARCH_RESULTS_PENDING':
            return { ...state, fetchResultState: 1, createState:0 }
        case 'SEARCH_RESULTS_FULFILLED':
            return { ...state, fetchResultState: 2, results: keyBy(action.payload.data, 'id')}
        case 'SEARCH_RESULTS_REJECTED':
            return {
                ...state,
                fetchResultState: 3,
                error: { ...state.error, fetch: action.payload.response.data }
            }

        case 'SEARCH_RESULT_SUMMARY_PENDING':
            return { ...state, summaryState: 1 }
        case 'SEARCH_RESULT_SUMMARY_FULFILLED':
            return { ...state, summaryState: 2, summary: action.payload.data}
        case 'SEARCH_RESULT_SUMMARY_REJECTED':
            return {
                ...state,
                summaryState: 3,
                error: { ...state.error, fetch: action.payload.response.data }
            }

        case 'SEARCH_PASS_RATE_PENDING':
            return { ...state, summaryState: 1 }
        case 'SEARCH_PASS_RATE_FULFILLED':
            return { ...state, summaryState: 2, passRate: action.payload.data}
        case 'SEARCH_PASS_RATE_REJECTED':
            return {
                ...state,
                fetchResultState: 3,
                error: { ...state.error, fetch: action.payload.response.data }
            }

        case 'SEARCH_GPS_DATA_PENDING':
            return { ...state, fetchResultState: 1 }
        case 'SEARCH_GPS_DATA_FULFILLED':
            return { ...state, fetchResultState: 2, gpsDataList: action.payload.data}
        case 'SEARCH_GPS_DATA_REJECTED':
            return {
                ...state,
                fetchResultState: 3,
                error: { ...state.error, fetch: _.get(action.payload,'response.data') }
            }

        case 'UPDATE_GPS_DATA_PENDING':
            return { ...state, updateState: 1 }
        case 'UPDATE_GPS_DATA_FULFILLED':
            return { ...state, updateState: 2, gpsDataList: _.map(state.gpsDataList,gpsData=>gpsData.id === action.payload.data.id
                    ? action.payload.data : gpsData)}
        case 'UPDATE_GPS_DATA_REJECTED':
            return {
                ...state,
                updateState: 3,
                error: { ...state.error, fetch: _.get(action.payload,'response.data') }
            }

        case 'GET_DASHBOARD_SUMMARY_PENDING':
            return { ...state, summaryState: 1 }
        case 'GET_DASHBOARD_SUMMARY_FULFILLED':
            return { ...state,summaryState: 2 , dashboardSummary: action.payload.data}
        case 'GET_DASHBOARD_SUMMARY_REJECTED':
            return {
                ...state,
                summaryState: 3,
                error:  _.get(action.payload,'response.data')
            }

        case 'DELETE_GPS_DATA_PENDING':
            return { ...state, deleteState: 1 }
        case 'DELETE_GPS_DATA_FULFILLED':
            console.log("action",action.payload.data)
            return { ...state,deleteState: 2 , gpsDataList: _.filter(state.gpsDataList,gpsData=>gpsData.id !== action.payload.data)}
        case 'DELETE_GPS_DATA_REJECTED':
            return {
                ...state,
                deleteState: 3,
                error:  _.get(action.payload,'response.data')
            }

        case 'UPLOAD_FILE_PENDING':
            return { ...state, fileUploadState: 1 }
        case 'UPLOAD_FILE_FULFILLED':
            return { ...state,fileUploadState: 2}
        case 'UPLOAD_FILE_REJECTED':
            return {
                ...state,
                fileUploadState: 3,
                error:  _.get(action.payload,'response.data')
            }

        case 'IS_DOWNLOADING':
            return {
                ...state,
                isDownloadingState: action.payload
            }

        default:
            return state
    }
}

export default reducer
