import React from 'react'
import { NavLink } from 'react-router-dom'

import { getGroups } from '../utils'

import logo from '../shared/img/logo.jpeg'
import './css/styles.css'
import {Auth} from "common-modules";
import { useHistory } from "react-router-dom";
const NavBar = () => {
    const history = useHistory()
    const _logout = () => {
        Auth.clearAll();
        history.push('/login');
        window.location.reload(true)
    }

    return (

        <nav className="navbar bg-dark navbar-dark sticky-top bg-light flex-md-nowrap p-0 shadow">
            <NavLink className="navbar-brand col-2 mr-0 px-3" to='/sales'>
                <img src={logo} width={32} height={32} alt="logo"/>&nbsp;&nbsp;&nbsp;
                <strong>T-Capture</strong>
            </NavLink>
            <button className="navbar-toggler position-absolute d-md-none collapsed" type="button"
                    data-toggle="collapse" data-target="#sidebarMenu" aria-controls="sidebarMenu"
                    aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"/>
            </button>

            <ul className="navbar-nav col-6 offset-9">
                <div className="row">
                    <div className="col-2 text-right">
                        <NavLink className="nav-link" to='/login' onClick={_logout}>Sign out</NavLink>
                    </div>
                </div>
            </ul>
        </nav>
    )
}

export default NavBar
