import React, {useRef} from 'react'
import {Auth} from "common-modules";

export  const isAdminUser=()=> {
    const user = Auth.getUser()
    return user && user.role === "COORDINATOR"

}

export  const getUsersRole=()=> {
    const user = Auth.getUser()
    return user.role

}

export const userRoles = [
    {key:'AGENT', value:'AGENT'},
    {key:'SUPERVISOR', value:'SUPERVISOR'},
    {key:'QC', value:'QC'},
    {key:'COORDINATOR', value:'COORDINATOR'}
]
