import React from 'react'

import '../../Main/static/css/dashboard.css'
import CreateZone from "./CreateZone";
import ZonesList from "./ZonesList";

const AddZones = () => {
    return (
        <main role="main" className="col-md-9 ml-sm-auto col-lg-10 px-md-4">
            <CreateZone/>
            <ZonesList/>
        </main>
    )
}

export default AddZones
