import React, {Fragment, useEffect, useState} from 'react'
import _, {get} from 'lodash'
import {connect, useDispatch} from 'react-redux'
import {useHistory} from "react-router-dom";
import SearchBox from "./SearchBox";
import {clearPollingUnits, searchPollingUnits} from "../actions";


const PollingUnitsList = props => {
    const dispatch = useDispatch()
    const history = useHistory()
    const { fetchState, error, pollingUnits } = props.pollingUnitReducer
    const [filterPollingUnitList,setFilterPollingUnitList] = useState(pollingUnits)
    const [isSearchActive, setIsSearchActive] = useState(false)

    useEffect(()=>{
        setFilterPollingUnitList([...pollingUnits])
    },[pollingUnits])

    const onSearch=(searchReq)=>{
        if(searchReq.wardId){
            setIsSearchActive(true)
            dispatch(searchPollingUnits(searchReq))
        }else{
            clearSearch()
        }
    }

    const clearSearch = () => {
        setIsSearchActive(false)
        dispatch(clearPollingUnits())
    }

    return (
        <Fragment>
            <SearchBox clearSearch={clearSearch} onSearch={onSearch}/>
            {
                fetchState === 2 &&
                <div className="my-3 p-3 bg-white rounded shadow-sm">
                    <div className='row'>
                        {filterPollingUnitList.map(pollingUnit => {
                                return (
                                    <div className='col-md-3'>
                                        <div className="card">
                                            <div className="card-body">
                                                <h5 className="card-title">{pollingUnit.name}</h5>
                                                <div> Ward: <span className='badge badge-primary'>{_.get(pollingUnit,'ward.name')}</span></div>
                                                <div> LGA: <span className='badge badge-primary'>{_.get(pollingUnit,'ward.lga.name')}</span></div>
                                                <div> State: <span className='badge badge-primary'>{_.get(pollingUnit,'ward.lga.state.name')}</span></div>
                                                <div> Zone: <span className='badge badge-primary'>{_.get(pollingUnit,'ward.lga.state.zone.name')}</span></div>
                                            </div>
                                            <div className='card-footer text-center'>
                                                <button type="button " className="btn btn-warning" onClick={e=>history.push('/pollingUnits/'+pollingUnit.id)} >
                                                    Edit
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }

                    </div>

                </div>
            }

            {
                fetchState === 1 &&
                <div className="row ">
                    <div className="col-md-12 text-center">
                        <div className="spinner-border text-primary" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                </div>
            }

            {
                fetchState === 3 &&
                <div className="alert alert-danger alert-dismissible fade show mt-1" role="alert">
                    {get(error, 'create')}
                    <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            }
            {fetchState === 2 && pollingUnits.length < 1 && isSearchActive &&
                <div className="alert alert-warning" role="alert">
                    No pollingUnits found
                </div>
            }

            {pollingUnits.length < 1 && !isSearchActive &&
                <div className="alert alert-warning tc" role="alert">
                   Please search with <b style={{color:'red'}}> ward </b> to get Polling units
                </div>
            }
        </Fragment>
    )
}

export default connect(state => ({
    pollingUnitReducer: state.pollingUnitReducer,
    modelReducer:state.modelReducer
}))(PollingUnitsList)
