import * as source from './source'



export function createResult (result) {
    return function (dispatch) {
        dispatch({
            type: 'CREATE_RESULT',
            payload: source.create(result)
        })
    }
}

export function searchResults (query) {
    return function (dispatch) {
        dispatch({
            type: 'SEARCH_RESULTS',
            payload: source.search(query)
        })
    }
}

export function searchResultSummary (query) {
    return function (dispatch) {
        dispatch({
            type: 'SEARCH_RESULT_SUMMARY',
            payload: source.searchResultSummary(query)
        })
    }
}


export function searchPassRate(query) {
    return function (dispatch) {
        dispatch({
            type: 'SEARCH_PASS_RATE',
            payload: source.searchPassRate(query)
        })
    }
}

export function searchGpsData(query) {
    return function (dispatch) {
        dispatch({
            type: 'SEARCH_GPS_DATA',
            payload: source.searchGpsData(query)
        })
    }
}

export function updateGpsData(id,req) {
    return  {
            type: 'UPDATE_GPS_DATA',
            payload: source.updateGpsData(id,req)
    }
}

export function getDashboardSummary() {
    return  {
        type: 'GET_DASHBOARD_SUMMARY',
        payload: source.getDashboardSummary()
    }
}

export function deleteGpsData(id) {
    return function (dispatch) {
        dispatch({
        type: 'DELETE_GPS_DATA',
        payload: source.deleteGpsData(id),
        })
    }
}

export function uploadFile(data) {
    return function (dispatch) {
        dispatch({
            type: 'UPLOAD_FILE',
            payload: source.uploadFile(data)
        })
    }
}

export function isDownloading(isDownload) {
    return  {
            type: 'IS_DOWNLOADING',
            payload: isDownload
    }
}

