import React, {Fragment, useEffect, useState} from 'react'
import {get} from 'lodash'
import {connect, useDispatch} from 'react-redux'

import {createZone, getZoneById, searchZones, updateZone} from '../actions'
import {Input, Select} from 'common-modules'
import {searchStates} from "../../State/actions";
import { useParams,useHistory } from "react-router-dom";
const CreateZone = props => {
    const params = useParams()
    const history = useHistory()
    const dispatch = useDispatch()
    const { createState, error, zone,zones } = props.zoneReducer
    const [createReq,setCreateReq] = useState({})

    const handleOnChange = (value,refer) => {
        createReq[refer] = value
        setCreateReq({...createReq})
    }
    const addZone = () => {
        dispatch(createZone(createReq))
    }

    useEffect(()=>{
       dispatch(getZoneById(params.id)).then(response=>{
           setCreateReq({...response.value.data})
       })
    },[params && params.id])


    const update = () =>{
        dispatch(updateZone(params.id,createReq)).then(()=>{
            history.push('/zones')
            dispatch(searchZones())
        })
    }


    return (

        <Fragment>
            <div className="row">
                <div className="col-md-12 text-center mt-5 " style={{justifyContent:'center',display: 'flex'}}>
                        <div className='col-md-6'>
                            <div className="card bg-light mb-3" style={{maxWidth:'100%'}}>
                                <div className="card-header pb-0"><h3> {params.id ? "Update Zone" : "Add Zones"}</h3></div>
                                <div className="card-body">
                                    <div className='mt-2'>
                                        <Input
                                            refer='name'
                                            handleOnChange={handleOnChange}
                                            value={get(createReq, 'name')}
                                            placeholder='Name'
                                            className="form-control mt-1"/>
                                    </div>
                                </div>

                            {
                               !params.id && createState !== 1 &&
                                <div className="row text-center mb-2 " style={{justifyContent:'center',display: 'flex'}}>
                                    <div className="col-md-3 ">
                                        <button
                                            onClick={addZone}
                                            className='btn btn-outline-success btn-block'>
                                            Create
                                        </button>
                                    </div>
                                </div>
                            }

                            {
                                params.id && createState !== 1 &&
                                <div className="row text-center mb-2 " style={{justifyContent:'center',display: 'flex'}}>
                                    <div className="col-md-3 ">
                                        <button
                                            onClick={update}
                                            className='btn btn-outline-primary btn-block'>
                                            Update
                                        </button>
                                    </div>
                                </div>
                            }

                            {
                                createState === 1 &&
                                <div className="row ">
                                    <div className="col-md-12 text-center">
                                        <div className="spinner-border text-primary" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                </div>
                            }

                            {
                                createState === 3 &&
                                <div className="alert alert-danger alert-dismissible fade show mt-1" role="alert">
                                    {get(error, 'create')}
                                    <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                            }
                            </div>
                        </div>
                </div>
            </div>
        </Fragment>
    )
}

export default connect(state => ({
    zoneReducer : state.zoneReducer,
    schoolReducer : state.schoolReducer
}))(CreateZone)
