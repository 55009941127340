import { get } from '../request';
import loginSource from './source';

export function fetchUsers() {
    return function (dispatch) {
        dispatch({
            type: 'FETCH_USERS',
            payload: loginSource.fetchUsers()
        });
    };
}

export function loginUser(auth) {
    return {
        type: 'LOGIN_USER',
        payload: loginSource.loginUser(auth)
    }
}

export function setUserInput(input) {
    return function (dispatch) {
        dispatch({
            type: 'SET_USER_INPUT',
            payload: input
        });
    };
}

export function removeUserInput() {
    return function (dispatch) {
        dispatch({
            type: 'REMOVE_USER_INPUT',
            payload: true
        });
    };
}

export function registerUser(reqParam) {
    return {
            type: 'REGISTER_USER',
            payload: loginSource.registerUser(reqParam)
    };
}

export function fetchUser(id) {
    return {
        type: 'FETCH_USER',
        payload: loginSource.fetchUser(id)
    };
}