import { keyBy ,get} from 'lodash'

const initialState = {
    pollingUnit: {},
    createState: 0,
    pollingUnits: [],
    fetchState: 0,

    error: {}
}

const reducer = (state = initialState, action) => {
    switch (action.type) {

        case 'CREATE_POLLING_UNIT_PENDING':
            return { ...state, createState: 1 }
        case 'CREATE_POLLING_UNIT_FULFILLED':
            const pollingUnit = action.payload.data
            return {
                ...state,
                createState: 2,
                pollingUnit: {},
                pollingUnits: [...state.pollingUnits,pollingUnit]
            }
        case 'CREATE_POLLING_UNIT_REJECTED':
            return {
                ...state,
                createState: 3,
                error: { ...state.error, create: get(action.payload,['response','data']) }
            }

        case 'GET_POLLING_UNITS_PENDING':
            return { ...state, fetchState: 1 }
        case 'GET_POLLING_UNITS_FULFILLED':

            return { ...state, fetchState: 2, pollingUnits:action.payload.data}
        case 'GET_POLLING_UNITS_REJECTED':
            return {
                ...state,
                fetchState: 3,
                error: { ...state.error, fetch: get(action.payload,['response','data']) }
            }

        case 'SEARCH_POLLING_UNITS_PENDING':
            return { ...state, fetchState: 1 }
        case 'SEARCH_POLLING_UNITS_FULFILLED':
            return { ...state, fetchState: 2, pollingUnits: action.payload.data}
        case 'SEARCH_POLLING_UNITS_REJECTED':
            return {
                ...state,
                fetchState: 3,
                error: { ...state.error, fetch: get(action.payload,['response','data']) }
            }

        case 'CLEAR_POLLING_UNITS':
            return { ...state, fetchState: 0, pollingUnits: []}

        default:
            return state
    }
}

export default reducer
