import request from '../request'

export const create = ward => {
    return request.post('wards', ward)
}

export const search = () => {
    return request.get('wards')
}

export const getWardById = id => {
    return request.get('wards/'+ id)
}

export const updateWard = (id,req) => {
    return request.patch('wards/'+ id,req)
}
