import { keyBy } from 'lodash'

const initialState = {
    admin: {},
    createState: 0,

    admins: {},
    fetchState: 0,
    error: {}
}

const reducer = (state = initialState, action) => {
    switch (action.type) {

        default:
            return state
    }
}

export default reducer