import React, {Fragment, useEffect, useState} from 'react'
import {get} from 'lodash'
import {connect, useDispatch} from 'react-redux'
import { useHistory } from "react-router-dom";
import {deleteUser, searchUsers} from '../actions'
import moment from "moment";
import {faPencil, faEye, faLocationDot, faUserGroup, faTrash} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {Auth} from "common-modules";
import $ from "jquery";
import {deleteGpsData} from "../../Dashboard/actions";

const UsersList = props => {
    const dispatch = useDispatch()
    const history = useHistory()
    const { fetchState, error, users } = props.userReducer
    const [selectedId,setSelectedId]  = useState(null)

    const confirmDelete = (data) =>{
        setSelectedId(data.id)
        $('#deleteModal').modal('show')
    }
    const onDelete = () =>{
        dispatch(deleteUser(selectedId))
        $('#deleteModal').modal('hide')
    }

    return (
        <Fragment>
            {
                fetchState === 2 &&
                    <div className='row'>
                        <div className='col-md-12 bg-black mv1'>
                            <div className='row'>
                                <div className='col-md-4'>
                                    <p className='white center'><b>Name</b></p>
                                </div>

                                <div className='col-md-2'>
                                    <p className='white center'><b>username</b></p>
                                </div>

                                <div className='col-md-2'>
                                    <p className='white center'><b>phone</b></p>
                                </div>
                                <div className='col-md-2'>
                                    <p className='white center'><b>Type</b></p>
                                </div>

                            </div>

                        </div>
                        {users.map(user => {
                                return (
                                    <div className='col-md-12 bg-black-10 p-3 mv1'>
                                        <div className='row'>
                                            <div className='col-md-3'>
                                                <p><b>{user.firstName} {user.lastName}</b></p>
                                            </div>

                                            <div className='col-md-2'>
                                                <p>{user.username}</p>
                                            </div>

                                            <div className='col-md-2'>
                                                <p>{user.phoneNumber}</p>
                                            </div>

                                            <div className='col-md-2'>
                                                <p>{user.role}</p>
                                            </div>

                                            <div className='col-md-3 text-center'>
                                                <button
                                                    onClick={()=>history.push('users/'+user.id,{ isEdit : true})}
                                                    className='btn btn-outline-success'>
                                                    <FontAwesomeIcon icon={faPencil} />
                                                </button>

                                                <button
                                                    onClick={()=>history.push('users/'+user.id,{ isEdit : false})}
                                                    className='btn btn-outline-primary ml-1'>
                                                    <FontAwesomeIcon icon={faEye} />
                                                </button>
                                                {user.role === "AGENT" &&
                                                    <button
                                                        onClick={() => history.push('/users/assign-plu/' + user.id)}
                                                        className='btn btn-outline-secondary ml-1'>
                                                        <FontAwesomeIcon icon={faLocationDot}/>
                                                    </button>
                                                }
                                                {(user.role === "SUPERVISOR" || user.role === "QC") &&
                                                    <button
                                                        onClick={() => history.push('/users/assign-agents/' + user.id)}
                                                        className='btn btn-outline-dark ml-1'>
                                                        <FontAwesomeIcon icon={faUserGroup}/>
                                                    </button>
                                                }
                                                {Auth.getUser() && user.id !== Auth.getUser().id &&
                                                    <button
                                                        onClick={()=>confirmDelete(user)}
                                                        className='btn btn-outline-danger ml-1'>
                                                        <FontAwesomeIcon icon={faTrash}/>
                                                    </button>
                                                }
                                            </div>

                                        </div>


                                    </div>
                                )
                            })
                        }
                </div>
            }

            {
                fetchState === 1 &&
                <div className="row ">
                    <div className="col-md-12 text-center">
                        <div className="spinner-border text-primary" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                </div>
            }

            {
                fetchState === 3 &&
                <div className="alert alert-danger alert-dismissible fade show mt-1" role="alert">
                    {get(error, 'create')}
                    <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            }
            {fetchState === 2 && Object.values(users).length < 1 &&
                <div className="alert alert-warning" role="alert">
                    No users found
                </div>
            }

            <div id="deleteModal" className="modal" tabIndex="-1" role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Confirm Delete</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <h5>Are you sure want to delete this User ?</h5>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button type="button" className="btn btn-danger" onClick={onDelete}>Delete</button>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default connect(state => ({
    userReducer: state.userReducer,
    modelReducer:state.modelReducer
}))(UsersList)
