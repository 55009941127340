import {getSafeValues} from 'common-modules'

const initialState = {
    fetchState: 0,
    fetchUserState: 0,
    users: [],

    errors: '',

    userInput: {},
    user: {},

    token: '',
    loginState: 0,
    loginError: '',
    registerUser : {},
    registerState:0,
    registerError:''
};

export default function goals(state = initialState, action) {
    switch (action.type) {
    case 'FETCH_USERS_PENDING':
        return { ...state, fetchState: 1 };
    case 'FETCH_USERS_FULFILLED':
        return { ...state, fetchState: 2, users: action.payload.data };
    case 'FETCH_USERS_REJECTED':
        return { ...state, fetchState: 3, errors: action.payload };

    case 'LOGIN_USER_PENDING':
        return { ...state, loginState: 1 };
    case 'LOGIN_USER_FULFILLED':
        return { ...state, loginState: 2, user: action.payload.data };
    case 'LOGIN_USER_REJECTED':
        console.log("FFG", getSafeValues(action.payload,'response','data'))
        return { ...state, loginState: 3, loginError: getSafeValues(action.payload,'response','data','message') };

    case 'SET_USER_INPUT':
        return { ...state, userInput: action.payload };
    case 'REMOVE_USER_INPUT':
        return { ...state, userInput: {email:'',password:''}};

    case 'REGISTER_USER_PENDING':
         return { ...state,registerState : 1};
    case 'REGISTER_USER_FULFILLED':
         return { ...state,registerState : 2, registerUser: action.payload.data };
    case 'REGISTER_USER_REJECTED':
         return { ...state,registerState : 3, registerError: getSafeValues(action.payload,'response','data') };

        case 'FETCH_USER_PENDING':
            return { ...state,fetchUserState : 1};
        case 'FETCH_USER_FULFILLED':
            return { ...state,fetchUserState : 2, user: action.payload.data };
        case 'FETCH_USER_REJECTED':
            return { ...state,fetchUserState : 3, errors: getSafeValues(action.payload,'response','data') };

    default:
        return state;
    }
}
