import {get, keyBy} from 'lodash'

const initialLGA = {
    LGA: {},
    createLGA: 0,
    LGAs: [],
    fetchLGA: 0,

    error: {}
}

const reducer = (lga = initialLGA, action) => {
    switch (action.type) {

        case 'CREATE_LGA_PENDING':
            return { ...lga, createLGA: 1 }
        case 'CREATE_LGA_FULFILLED':
            const LGA = action.payload.data
            return {
                ...lga,
                createLGA: 2,
                LGA: {},
                LGAs:  [  ...lga.LGAs, LGA ]
            }
        case 'CREATE_LGA_REJECTED':
            return {
                ...lga,
                createLGA: 3,
                error: { ...lga.error, create: get(action.payload,['response','data']) }
            }

        case 'GET_LGAS_PENDING':
            return { ...lga, fetchLGA: 1 }
        case 'GET_LGAS_FULFILLED':
            return {...lga, fetchLGA: 2, LGAs: action.payload.data}
        case 'GET_LGAS_REJECTED':
            return {
                ...lga,
                fetchLGA: 3,
                error: { ...lga.error, fetch: get(action.payload,['response','data'])}
            }

        case 'SEARCH_LGAS_PENDING':
            return { ...lga, fetchLGA: 1 }
        case 'SEARCH_LGAS_FULFILLED':
            return { ...lga, fetchLGA: 2, LGAs: action.payload.data}
        case 'SEARCH_LGAS_REJECTED':
            return {
                ...lga,
                fetchLGA: 3,
                error: { ...lga.error, fetch: get(action.payload,['response','data'])}
            }

        default:
            return lga
    }
}

export default reducer
