import React, {Fragment, useState} from 'react'
import {get} from 'lodash'
import {connect, useDispatch} from 'react-redux'

import {createResult, searchResults} from '../actions'
import {Input, Select} from 'common-modules'
import dataSource from "../../Main/dataSource";
import resultsReducer from "../reducer";
import {getUsersSchool, isAdminUser} from "../../Utils/utils";

const SummaryCard = props => {
    const dispatch = useDispatch()
    const { createState, error, summary,passRate} = props.resultsReducer
    const [searchReq,setSearchReq] = useState({})


    return (

        <Fragment>
            <div className="row">
                <div className="col-md-3 text-center mt-5">
                    <span className='badge badge-success w-100'>
                        <p className='mt-2' style={{fontSize:24}}>100-Marks: {summary[100]}</p>
                        <p style={{fontSize:24}}>99-90 : {summary['90-99']}</p>
                        <p style={{fontSize:24}}>89-80 : {summary['80-89']}</p>
                    </span>
                </div>
                <div className="col-md-2 text-center mt-5">
                    <span className='badge badge-primary w-100'>
                        <p className='mt-2' style={{fontSize:24}}>79-70: {summary['70-79']}</p>
                        <p style={{fontSize:24}}>69-60 : {summary['60-69']}</p>
                        <p style={{fontSize:24}}>59-50 : {summary['50-59']}</p>
                    </span>
                </div>
                <div className="col-md-2 text-center mt-5">
                    <span className='badge badge-warning w-100'>
                        <p className='mt-2' style={{fontSize:24}}>49-40: {summary['40-49']}</p>
                        <p style={{fontSize:24}}>39-30 : {summary['30-39']}</p>
                        <p style={{fontSize:24}}>29-20 : {summary['20-29']}</p>
                    </span>
                </div>
                <div className="col-md-2 text-center mt-5">
                    <span className='badge badge-danger w-100'>
                        <p className='mt-2' style={{fontSize:24}}>19-10: {summary['10-19']}</p>
                        <p style={{fontSize:24}}>9-1 : {summary['1-9']}</p>
                        <p style={{fontSize:24}}>0-Marks : {summary['0']}</p>
                    </span>
                </div>
                <div className="col-md-3 text-center mt-5">
                    <span className='badge badge-secondary w-100'>
                        <p className='mt-2' style={{fontSize:48}}>{passRate.passRate ? get(passRate,'passRate',0).toFixed(2) : 0} %</p>
                        <p style={{fontSize:24}}>Pass</p>
                        <p style={{fontSize:24}}></p>
                    </span>
                </div>
            </div>
        </Fragment>
    )
}

export default connect(state => ({
    resultsReducer:state.resultsReducer
}))(SummaryCard)
