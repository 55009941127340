import React, {Fragment, useEffect, useState} from 'react'
import _, {get} from 'lodash'
import {connect, useDispatch} from 'react-redux'

import {createPollingUnit, getPollingUnitById, searchPollingUnits, updatePollingUnit} from '../actions'
import {Input, Select} from 'common-modules'
import {searchStates} from "../../State/actions";
import { useParams,useHistory } from "react-router-dom";
const CreatePollingUnit = props => {
    const params = useParams()
    const history = useHistory()
    const dispatch = useDispatch()
    const { createState, error, pollingUnit,pollingUnits } = props.pollingUnitReducer
    const { zones} = props.zoneReducer
    const { states} = props.stateReducer
    const { LGAs} = props.lgaReducer
    const { wards} = props.wardReducer
    const [createReq,setCreateReq] = useState({})

    const handleOnChange = (value,refer) => {
        createReq[refer] = value
        setCreateReq({...createReq})
    }
    const addPollingUnit = () => {

        dispatch(createPollingUnit(createReq))
    }

    useEffect(()=>{
        if(params.id)
            dispatch(getPollingUnitById(params.id)).then(response=>{
                setCreateReq({...response.value.data, zoneId: _.get(response.value.data,'ward.lga.state.zone.id'),
                    stateId: _.get(response.value.data,'ward.lga.state.id'), lgaId: _.get(response.value.data,'ward.lga.id')})
            })
    },[])

    const update = () =>{
        dispatch(updatePollingUnit(params.id,createReq)).then(()=>{
            history.goBack()
            // dispatch(searchPollingUnits())
        })
    }


    return (

        <Fragment>
            <div className="row">
                <div className="col-md-12 text-center mt-5 " style={{justifyContent:'center',display: 'flex'}}>
                        <div className='col-md-6'>
                            <div className="card bg-light mb-3" style={{maxWidth:'100%'}}>
                                <div className="card-header pb-0"><h3> {params.id ? "Update PollingUnit" : "Add PollingUnits"}</h3></div>
                                <div className="card-body">
                                    <div className='mt-2'>
                                        <Input
                                            refer='name'
                                            handleOnChange={handleOnChange}
                                            value={get(createReq, 'name')}
                                            placeholder='Name'
                                            className="form-control mt-1"/>
                                    </div>
                                    <div className='mt-2'>
                                        <Input
                                            refer='code'
                                            handleOnChange={handleOnChange}
                                            value={get(createReq, 'code')}
                                            placeholder='Code'
                                            className="form-control mt-1"/>
                                    </div>
                                    <div className='mt-2'>
                                        <Select
                                            refer='zoneId'
                                            handleOnChange={handleOnChange}
                                            value={get(createReq, 'zoneId')}
                                            placeholder='Zone'
                                            items ={zones}
                                            optionValue='name'
                                            optionKey='id'
                                            className="form-control mt-1"/>
                                    </div>
                                    <div className='mt-2'>
                                        <Select
                                            refer='stateId'
                                            handleOnChange={handleOnChange}
                                            value={get(createReq, 'stateId')}
                                            placeholder='State'
                                            items ={_.filter(states,state => _.get(state,'zone.id','') === createReq.zoneId)}
                                            optionValue='name'
                                            optionKey='id'
                                            className="form-control mt-1"/>
                                    </div>
                                    <div className='mt-2'>
                                        <Select
                                            refer='lgaId'
                                            handleOnChange={handleOnChange}
                                            value={get(createReq, 'lgaId')}
                                            placeholder='LGA'
                                            items ={_.filter(LGAs,lga => _.get(lga,'state.id') === createReq.stateId)}
                                            optionValue='name'
                                            optionKey='id'
                                            className="form-control mt-1"/>
                                    </div>
                                    <div className='mt-2'>
                                        <Select
                                            refer='wardId'
                                            handleOnChange={handleOnChange}
                                            value={get(createReq, 'wardId')}
                                            placeholder='Ward'
                                            items ={_.filter(wards,ward => _.get(ward,'lga.id') === createReq.lgaId)}
                                            optionValue='name'
                                            optionKey='id'
                                            className="form-control mt-1"/>
                                    </div>
                                </div>

                            {
                               !params.id && createState !== 1 &&
                                <div className="row text-center mb-2 " style={{justifyContent:'center',display: 'flex'}}>
                                    <div className="col-md-3 ">
                                        <button
                                            onClick={addPollingUnit}
                                            className='btn btn-outline-success btn-block'>
                                            Create
                                        </button>
                                    </div>
                                </div>
                            }

                            {
                                params.id && createState !== 1 &&
                                <div className="row text-center mb-2 " style={{justifyContent:'center',display: 'flex'}}>
                                    <div className="col-md-3 ">
                                        <button
                                            onClick={update}
                                            className='btn btn-outline-primary btn-block'>
                                            Update
                                        </button>
                                    </div>
                                </div>
                            }

                            {
                                createState === 1 &&
                                <div className="row ">
                                    <div className="col-md-12 text-center">
                                        <div className="spinner-border text-primary" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                </div>
                            }

                            {
                                createState === 3 &&
                                <div className="alert alert-danger alert-dismissible fade show mt-1" role="alert">
                                    {get(error, 'create')}
                                    <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                            }
                            </div>
                        </div>
                </div>
            </div>
        </Fragment>
    )
}

export default connect(state => ({
    pollingUnitReducer : state.pollingUnitReducer,
    zoneReducer : state.zoneReducer,
    stateReducer : state.stateReducer,
    lgaReducer : state.lgaReducer,
    wardReducer : state.wardReducer,

}))(CreatePollingUnit)
