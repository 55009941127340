import React, {Component, useEffect, useState} from 'react'
import _get from "lodash/get";
import {Link, withRouter} from "react-router-dom";
import {setUserInput,registerUser,removeUserInput} from "../actions";
import connect from "react-redux/es/connect/connect";
import {Input} from 'common-modules'
import {useDispatch, useSelector} from "react-redux";

const _Register = (props) => {

    const [validationError,setValidationError] = useState('')
    const [userReq,setUserReq] = useState({})
    const dispatch = useDispatch()
    const {fetchState,users,errors,registerState,registerError} = useSelector(state => state.loginReducer)

    const registerUser = () => {
        userReq.isAdmin = false
        const {firstName,lastName,
            email, confirmEmail, password, confirmPassword,
        } = { ...userReq};


        const requiredFields = [firstName,lastName,email,confirmEmail, password, confirmPassword]

        if (requiredFields.includes('')
        || requiredFields.includes(undefined)) {
            console.log('Required fields are empty');
            setValidationError('Required fields are empty');
            return;
        }

        if (email !== confirmEmail) {
            setValidationError('Emails do not match');
            return;
        }

        if (password.length < 5) {
            setValidationError('Password must contains at least 5 characters');
            return;
        }

        if (password !== confirmPassword) {
            setValidationError('Passwords do not match');
            return;
        }

        const registerReq = { ...userReq}
        delete registerReq["confirmEmail"];
        delete registerReq["confirmPassword"];

        dispatch(registerUser(registerReq)).then(result => {
            props.history.push('/login');
        }).catch(err => console.log('err', err));
    };

    const handleOnChange = (value,refer) => {
        const userReq = userReq;
        userReq[refer] = value;
        this.forceUpdate()
    }

    useEffect(()=>{
        dispatch(removeUserInput());
    },[])

        return (
            <div>
                <div>
                    <form>
                        <div className='row mt-5'>
                            <div className="col-md-4">
                                <label>First Name :</label>
                            </div>
                            <div className="col-md-8">
                                 <Input
                                    refer='firstName'
                                    handleOnChange={handleOnChange}
                                    value={userReq.firstName}
                                    placeholder='Enter First name'/>
                            </div>
                        </div>

                        <div className='row mt-4'>
                            <div className="col-md-4">
                                <label>Last Name :</label>
                            </div>
                            <div className="col-md-8">
                                <Input
                                    refer='lastName'
                                    handleOnChange={handleOnChange}
                                    value={userReq.lastName}
                                    placeholder='Enter Last name'/>
                            </div>
                        </div>

                        <div className='row mt-4'>
                            <div className="col-md-4">
                                <label>Telephone :</label>
                            </div>
                            <div className="col-md-8">
                                <Input
                                    refer='phone'
                                    handleOnChange={handleOnChange}
                                    value={userReq.phone}
                                    placeholder='Enter telephone'/>
                            </div>
                        </div>

                        <div className='row mt-4'>
                            <div className="col-md-4">
                                <label>Username :</label>
                            </div>
                            <div className="col-md-8">
                                <Input
                                    refer='username'
                                    handleOnChange={handleOnChange}
                                    value={userReq.username}
                                    placeholder='Enter username'/>
                            </div>
                        </div>

                        <div className='row mt-4'>
                            <div className="col-md-4">
                                <label>Email address :</label>
                            </div>
                            <div className="col-md-8">
                                <Input
                                    refer='email'
                                    handleOnChange={handleOnChange}
                                    value={userReq.email}
                                    placeholder='Enter e-mail'/>
                            </div>
                        </div>

                        <div className='row mt-4'>
                            <div className="col-md-4">
                                <label>Confirm email address :</label>
                            </div>
                            <div className="col-md-8">
                                <Input
                                    refer='confirmEmail'
                                    handleOnChange={handleOnChange}
                                    value={userReq.confirmEmail}
                                    placeholder='Confirm e-mail'/>
                            </div>
                        </div>


                        <div className='row mt-4'>
                            <div className="col-md-4">
                                <label>Password :</label>
                            </div>
                            <div className="col-md-8">
                                <Input
                                    type='password'
                                    refer='password'
                                    handleOnChange={handleOnChange}
                                    value={userReq.password}
                                    placeholder='Enter password'/>
                                <label htmlFor='usr'>* Must be at least 8 characters,
                                    and ideally contain some or all of:
                                    CAPS and lower case, numbers, random characters like #!*.
                                </label>
                            </div>
                        </div>

                        <div className='row mt-4'>
                            <div className="col-md-4">
                                <label>Confirm password:</label>
                            </div>
                            <div className="col-md-8">
                                <Input
                                    type='password'
                                    refer='confirmPassword'
                                    handleOnChange={handleOnChange}
                                    value={userReq.confirmPassword}
                                    placeholder='Confirm password'/>
                            </div>
                        </div>


                    </form>
                </div>

                {
                    validationError
                    && <h5 style={{ color: 'red' }}>
                        {validationError}
                    </h5>
                }
                {
                    registerState ===3 && registerError &&
                     <h5 style={{ color: 'red' }}>
                        {registerError}
                    </h5>
                }
                {
                    registerState ===1 &&
                    <h5>
                        Loading....
                    </h5>
                }
                {
                    registerState === 2 &&
                    <h5 style={{ color: 'green' }}>
                        Success
                    </h5>
                }
                <a className="f6 mt-5 grow no-underline br-pill ph3 pv2 mb2 dib hover-white white bg-blue w-75"
                   style={{textDecoration: 'none'}} href="javascript: void(0)"
                   onClick={registerUser} >REGISTER</a>
                <div>
                    <label>
                        Have an account ?
                        <b className='appLink'> <Link className='blue hover-light-blue' to='/login'> Login</Link></b>
                    </label>
                </div>
            </div>
        )


}

export default _Register