import React, {Fragment, useEffect, useRef, useState} from 'react'

import '../../Main/static/css/dashboard.css'

import ResultsList from "./ResultsList";
import SearchDashboard from "./SearchDashboard";
import SummaryCard from "./SummaryCard";
import {Bar, CartesianGrid, Cell, Legend, Pie, PieChart, BarChart, Tooltip, XAxis, YAxis} from "recharts";
import {connect, useDispatch} from "react-redux";
import {getDashboardSummary, getWardSummary, resetWardSummary, searchGpsData} from "../actions";
import _, {get} from "lodash";
import {getUsersRole} from "../../Utils/utils";
import {Auth, Select} from "common-modules";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDownload, faRefresh} from "@fortawesome/free-solid-svg-icons";
import TableToExcel from "@stanlystark/table-to-excel";

const WardSummary = props => {
    const dispatch = useDispatch()
    const { wardSummaryList,wardSummaryState, error } = props.resultsReducer
    const initSearchReq =getUsersRole() === "COORDINATOR" ? {} :
        {stateIdList: Auth.getUser().stateIdList}
    const [searchReq,setSearchReq] = useState(initSearchReq)
    const { zones } = props.zoneReducer
    const { states } = props.stateReducer
    const { LGAs } = props.lgaReducer

    const gpsDataType = getUsersRole() === "QC" || props.match.path === '/report2' ? 'gps-data2' : 'gps-data'

    useEffect(()=>{
        dispatch(resetWardSummary())
    },[props.match.path])

    const clearSearch = () =>{
        setSearchReq({...initSearchReq});
        dispatch(resetWardSummary())
    }

    const handleOnChange = (value,refer) => {
        searchReq[refer] = value
        setSearchReq({...searchReq})
    }

    const searchResult = () =>{
            dispatch(getWardSummary(gpsDataType,searchReq.stateId,searchReq.lgaId))
    }

    const onDownload  = () =>{
        if(wardSummaryList.length < 1)
            return
        TableToExcel.convert(document.getElementById("summary-table"), {
            name: "Summary_table.xlsx",
            sheet: {
                name: "Summary"
            }
        });
    }


    return (
        <main role="main" className="col-md-9 ml-sm-auto col-lg-10 px-md-4">
            <Fragment>
        <div className='row mt-4'>
                <div className='col-md-12'>
                    <h3 className=''>Ward summary</h3>

                    <div className="card bg-lightest-blue mb-3" style={{maxWidth:'100%'}}>
                        <div className="card-body">
                            <h5 className='text-left'>Search Results</h5>
                            <div className='row '>
                                {getUsersRole() === "COORDINATOR" &&
                                    <div className='col-md-3 mt-2'>
                                        <Select
                                            refer='zoneId'
                                            handleOnChange={handleOnChange}
                                            value={get(searchReq, 'zoneId')}
                                            placeholder='Zone'
                                            items={zones}
                                            optionValue='name'
                                            optionKey='id'
                                            className="form-control mt-1"/>
                                    </div>
                                }
                                <div className='col-md-3 mt-2'>
                                    <Select
                                        refer='stateId'
                                        handleOnChange={handleOnChange}
                                        value={get(searchReq, 'stateId')}
                                        placeholder='State'
                                        items ={getUsersRole() === "COORDINATOR" ?
                                            _.filter(states,state => _.get(state,'zone.id') === searchReq.zoneId) :
                                            _.filter(states,state => _.get(Auth.getUser(),'stateIdList', []).includes(state.id))}

                                        optionValue='name'
                                        optionKey='id'
                                        className="form-control mt-1"/>
                                </div>

                                <div className='col-md-3 mt-2'>
                                    <Select
                                        refer='lgaId'
                                        handleOnChange={handleOnChange}
                                        value={get(searchReq, 'lgaId')}
                                        placeholder='LGA'
                                        items ={_.filter(LGAs,lga => _.get(lga,'state.id') === searchReq.stateId)}
                                        optionValue='name'
                                        optionKey='id'
                                        className="form-control mt-1"/>
                                </div>
                                <div className="col-md-3 mt-2">
                                    <div className={'row'}>
                                        <button
                                            style={{width:'50%'}}
                                            onClick={searchResult}
                                            className='btn btn-success btn-block'>
                                            Search
                                        </button>
                                        <button
                                            style={{width:'20%', margin:4}}
                                            onClick={clearSearch}
                                            className='btn btn-danger btn-block'>
                                            <FontAwesomeIcon icon={faRefresh} />
                                        </button>
                                        <button
                                                style={{width: '20%', margin: 4}}
                                                onClick={onDownload}
                                                className='btn btn-secondary btn-block'>
                                                <FontAwesomeIcon icon={faDownload}/>
                                        </button>
                                    </div>
                                </div>

                            </div>
                        </div>

                        {
                            wardSummaryState === 1 &&
                            <div className="row ">
                                <div className="col-md-12 text-center">
                                    <div className="spinner-border text-primary" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </div>
                            </div>
                        }

                        {
                            wardSummaryState === 3 &&
                            <div className="alert alert-danger alert-dismissible fade show mt-1" role="alert">
                                {get(error, 'create')}
                                <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                        }
                    </div>

                    {wardSummaryState !== 1 && wardSummaryList.length < 1 ?
                        <div className="alert alert-warning tc" role="alert">
                            No results found, Please use search filter to view data !!!
                        </div>
                        :
                        <table width='80%' border={1} className="table" id='summary-table'>
                            <tr bgcolor='black' style={{color: 'white'}}>
                                <th width="40%">Ward</th>
                                <th width="20%">Total Polling Units</th>
                                <th width="20%">Captured Polling Units</th>
                                <th width="20%">Percentage</th>
                            </tr>
                            {wardSummaryList && wardSummaryList.map(stateData => {
                                return <><tr align='center'>
                                    <th style={{alignItems:'center'}} bgcolor='#add8e6' width="100%" colSpan={4}>{stateData.lgaName}</th></tr>
                                {_.get(stateData,'gpsDataRespList',[]).map(lgaData=>{
                                    return <tr>
                                                <td width="40%">{lgaData.name}</td>
                                                <td width="20%">{lgaData.totalPU}</td>
                                                <td width="20%">{lgaData.pucaptured}</td>
                                                <td width="20%">{(lgaData.pucaptured / lgaData.totalPU * 100).toFixed(2)} %</td>
                                            </tr>
                                })
                                }
                                </>
                                })
                            }
                        </table>
                    }
                </div>
            </div>
</Fragment>
</main>
    )
}

export default connect(state => ({
    resultsReducer: state.resultsReducer,
    zoneReducer: state.zoneReducer,
    stateReducer: state.stateReducer,
    lgaReducer: state.lgaReducer,
}))(WardSummary)
