import React, {Fragment, useEffect, useRef, useState} from 'react'
import _, {get} from 'lodash'
import {connect, useDispatch} from 'react-redux'

import {createResult, searchGpsData, searchPassRate, searchResults, searchResultSummary} from '../actions'
import {Auth, Input, Select} from 'common-modules'
import dataSource from "../../Main/dataSource";
import resultsReducer from "../reducer";
import {getUsersRole, isAdminUser} from "../../Utils/utils";
import {faDownload, faEye, faRefresh} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import TableToExcel from "@stanlystark/table-to-excel";

const SearchBox = props => {
    const { zones } = props.zoneReducer
    const { states } = props.stateReducer
    const { LGAs } = props.lgaReducer
    const { wards } = props.wardReducer
    const initSearchReq =getUsersRole() === "SUPERVISOR" ?
        {agentIdList: Auth.getUser().agentIdList}:{}
    const [searchReq,setSearchReq] = useState(initSearchReq)


    const handleOnChange = (value,refer) => {
        searchReq[refer] = value
        setSearchReq({...searchReq})
    }
    const onSearch = () => {
        props.onSearch(searchReq)
    }

    useEffect(()=>{

    },[])

    const clearSearch = () =>{
        setSearchReq({...initSearchReq});
        props.clearSearch();
    }


    return (

        <Fragment>
            <div className="row">
                <div className="col-md-12 text-center mt-5 " style={{justifyContent:'center',display: 'flex'}}>
                        <div className='col-md-12'>
                            <div className="card bg-lightest-blue mb-3" style={{maxWidth:'100%'}}>
                                <div className="card-body">
                                    <h5 className='text-left'>Search Results</h5>
                                    <div className='row '>

                                        <div className='col-md-2 mt-2'>
                                            <Select
                                                refer='zoneId'
                                                handleOnChange={handleOnChange}
                                                value={get(searchReq, 'zoneId')}
                                                placeholder='Zone'
                                                items ={zones}
                                                optionValue='name'
                                                optionKey='id'
                                                className="form-control mt-1"/>
                                        </div>

                                        <div className='col-md-2 mt-2'>
                                            <Select
                                                refer='stateId'
                                                handleOnChange={handleOnChange}
                                                value={get(searchReq, 'stateId')}
                                                placeholder='State'
                                                items ={_.filter(states,state => _.get(state,'zone.id') === searchReq.zoneId)}
                                                optionValue='name'
                                                optionKey='id'
                                                className="form-control mt-1"/>
                                        </div>

                                        <div className='col-md-2 mt-2'>
                                            <Select
                                                refer='lgaId'
                                                handleOnChange={handleOnChange}
                                                value={get(searchReq, 'lgaId')}
                                                placeholder='LGA'
                                                items ={_.filter(LGAs,lga => _.get(lga,'state.id') === searchReq.stateId)}
                                                optionValue='name'
                                                optionKey='id'
                                                className="form-control mt-1"/>
                                        </div>
                                            <div className='col-md-2 mt-2'>
                                                <Select
                                                    refer='wardId'
                                                    handleOnChange={handleOnChange}
                                                    value={get(searchReq, 'wardId')}
                                                    placeholder='Ward'
                                                    items ={_.filter(wards,ward => _.get(ward,'lga.id') === searchReq.lgaId)}
                                                    optionValue='name'
                                                    optionKey='id'
                                                    className="form-control mt-1"/>
                                        </div>
                                        <div className="col-md-4 mt-2">
                                            <div className={'row'}>
                                                <button
                                                    style={{width:'50%'}}
                                                    onClick={onSearch}
                                                    className='btn btn-success btn-block'>
                                                    Search
                                                </button>
                                                <button
                                                    style={{width:'20%', margin:4}}
                                                    onClick={clearSearch}
                                                    className='btn btn-danger btn-block'>
                                                    <FontAwesomeIcon icon={faRefresh} />
                                                </button>

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                </div>
            </div>
        </Fragment>
    )
}

export default connect(state => ({
    resultsReducer:state.resultsReducer,
    zoneReducer:state.zoneReducer,
    stateReducer:state.stateReducer,
    lgaReducer:state.lgaReducer,
    wardReducer:state.wardReducer,

}))(SearchBox)
