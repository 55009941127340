import React from 'react'

import '../../Main/static/css/dashboard.css'
import CreateWard from "./CreateWard";
import WardsList from "./WardsList";

const AddWards = () => {
    return (
        <main role="main" className="col-md-9 ml-sm-auto col-lg-10 px-md-4">
            <CreateWard/>
            <WardsList/>
        </main>
    )
}

export default AddWards
