import request from '../request'

export const create = state => {
    return request.post('states', state)
}

export const search = () => {
    return request.get('states', )
}

export const getStateById = id => {
    return request.get('states/'+ id)
}

export const updateState = (id,req) => {
    return request.patch('states/'+ id,req)
}
