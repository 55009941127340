import React, {Fragment, useEffect, useRef} from 'react'

import '../../Main/static/css/dashboard.css'

import ResultsList from "./ResultsList";
import SearchDashboard from "./SearchDashboard";
import SummaryCard from "./SummaryCard";
import {Bar, CartesianGrid, Cell, Legend, Pie, PieChart, BarChart, Tooltip, XAxis, YAxis} from "recharts";
import {connect, useDispatch} from "react-redux";
import {getDashboardSummary} from "../actions";
import _ from "lodash";
import {MapContainer} from "common-modules";
import {useHistory, useParams} from "react-router-dom";

const MapView = props => {

    const dispatch = useDispatch()
    const history = useHistory()
    const params = new URLSearchParams(history.location.search);
    console.log(params.get('longitude'))
    console.log(params.get('latitude'))
    console.log(params.get('pu'))


    return (
        <main role="main" className="col-md-9 ml-sm-auto col-lg-10 px-md-4">
        <Fragment>
            <div className='row mt-4'>
               <div className='col-md-10 offset-1' style={{height:'580px'}}>
                   <MapContainer apiKey="AIzaSyCkDdPGvazynTQLBpELhCAXalXxK0a3xC0"
                                 defaultZoom={15}
                                 disableOnMarkerClick={false}
                                 mapTypeId="hybrid"
                                 content={{longitude:parseFloat(params.get('longitude')), latitude: parseFloat(params.get('latitude'))}}
                                 isShowInfoWindow={true}
                                 infoWindowContent={
                                     <div className='row ml-3 mr-3'>
                                         <p className='col-md-12'>
                                             {params.get('pu')}
                                         </p>

                                     </div>
                                 }
                   />
               </div>
            </div>
        </Fragment>
        </main>
    )
}

export default connect(state => ({
    resultsReducer: state.resultsReducer,
}))(MapView)
