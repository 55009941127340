import { keyBy ,get} from 'lodash'

const initialState = {
    zone: {},
    createState: 0,
    zones: [],
    fetchState: 0,

    error: {}
}

const reducer = (state = initialState, action) => {
    switch (action.type) {

        case 'CREATE_ZONE_PENDING':
            return { ...state, createState: 1 }
        case 'CREATE_ZONE_FULFILLED':
            const zone = action.payload.data
            return {
                ...state,
                createState: 2,
                zone: {},
                zones: [...state.zones,zone]
            }
        case 'CREATE_ZONE_REJECTED':
            return {
                ...state,
                createState: 3,
                error: { ...state.error, create: get(action.payload,['response','data']) }
            }

        case 'GET_ZONES_PENDING':
            return { ...state, fetchState: 1 }
        case 'GET_ZONES_FULFILLED':

            return { ...state, fetchState: 2, zones:action.payload.data}
        case 'GET_ZONES_REJECTED':
            return {
                ...state,
                fetchState: 3,
                error: { ...state.error, fetch: get(action.payload,['response','data']) }
            }

        case 'SEARCH_ZONES_PENDING':
            return { ...state, fetchState: 1 }
        case 'SEARCH_ZONES_FULFILLED':
            console.log("action.payload.data",action.payload.data)
            return { ...state, fetchState: 2, zones: action.payload.data}
        case 'SEARCH_ZONES_REJECTED':
            return {
                ...state,
                fetchState: 3,
                error: { ...state.error, fetch: get(action.payload,['response','data']) }
            }

        default:
            return state
    }
}

export default reducer
