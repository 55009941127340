import * as source from './source'



export function createLGA (req) {
    return function (dispatch) {
        dispatch({
            type: 'CREATE_LGA',
            payload: source.create(req)
        })
    }
}

export function searchLGAs () {
    return function (dispatch) {
        dispatch({
            type: 'SEARCH_LGAS',
            payload: source.search()
        })
    }
}

export function getLGAById (query) {
    return {
        type: 'GET_LGA_BY_ID',
        payload: source.getLGAById(query)
    }
}

export function updateLGA (id,req) {
    return {
        type: 'UPDATE_LGA',
        payload: source.updateLGA(id,req)
    }
}


