import React from 'react'
import {BrowserRouter, Redirect, Route, Switch} from 'react-router-dom'

import {Auth} from "common-modules";
import NavBar from "./shared/NavBar";
import AddZones from "./Zone/components/AddZones";
import AddStates from "./State/components/AddStates";
import Dashboard from "./Dashboard/components/Dashboard";
import Dashboard2 from "./Dashboard2/components/Dashboard";
import GPSData from "./Dashboard/components/GPSData";
import GPSData2 from "./Dashboard2/components/GPSData";
import SideMenu from "./Main/components/SideMenu";
import LoginRouter from "./Login/router";
import CreateZone from "./Zone/components/CreateZone";
import CreateState from "./State/components/CreateState";
import AddLGAs from "./LGA/components/AddLGAs";
import CreateLGA from "./LGA/components/CreateLGA";
import AddWards from "./Ward/components/AddWards";
import CreateWard from "./Ward/components/CreateWard";
import AddPollingUnits from "./PollingUnits/components/AddPollingUnits";
import CreatePollingUnit from "./PollingUnits/components/CreatePollingUnit";
import AddUsers from "./User/components/AddUsers";
import CreateUser from "./User/components/CreateUser";
import AssignPLU from "./User/components/AssignPLU";
import AssignAgents from "./User/components/AssignAgents";
import {getUsersRole, isAdminUser} from "./Utils/utils";
import MapView from "./Dashboard/components/MapView";
import WardSummary from "./Dashboard/components/WardSummary";


export default () => {
    return (
        <BrowserRouter>
            <div>
                <Route
                    path='/'
                    render={routeProps => (
                        <div>
                            {Auth.getToken() &&
                            <div>
                                <NavBar{...routeProps}/>
                                <SideMenu {...routeProps}/>

                                    {isAdminUser() ?
                                        <Switch>
                                            <Route path='/zones' component={AddZones} exact/>
                                            <Route path='/zones/:id' component={CreateZone} exact/>
                                            <Route path='/states' component={AddStates} exact/>
                                            <Route path='/states/:id' component={CreateState} exact/>
                                            <Route path='/lgas' component={AddLGAs} exact/>
                                            <Route path='/lgas/:id' component={CreateLGA} exact/>
                                            <Route path='/wards' component={AddWards} exact/>
                                            <Route path='/wards/:id' component={CreateWard} exact/>
                                            <Route path='/pollingUnits' component={AddPollingUnits} exact/>
                                            <Route path='/pollingUnits/:id' component={CreatePollingUnit} exact/>
                                            <Route path='/users' component={AddUsers} exact/>
                                            <Route path='/users/assign-plu/:id' component={AssignPLU} exact/>
                                            <Route path='/users/assign-agents/:id' component={AssignAgents} exact/>
                                            <Route path='/users/:id' component={CreateUser} exact/>
                                            <Route path='/dashboard' component={Dashboard} exact/>
                                            <Route path='/dashboard2' component={Dashboard2} exact/>
                                            <Route path='/gps-data' component={GPSData} exact/>
                                            <Route path='/gps-data2' component={GPSData2} exact/>
                                            <Route path='/report' component={WardSummary} exact/>
                                            <Route path='/report2' component={WardSummary} exact/>
                                            <Route path='/map-view' component={MapView} exact/>
                                            <Redirect to='/dashboard'/>
                                        </Switch> :
                                        <Switch>
                                            {getUsersRole() === "QC" &&
                                                <Route path='/dashboard' component={Dashboard2} exact/>
                                            }
                                            {getUsersRole() === "QC" &&
                                                <Route path='/report' component={WardSummary} exact/>
                                            }
                                            <Route path='/gps-data' component={getUsersRole() === "QC" ? GPSData2 : GPSData} exact/>
                                            <Route path='/map-view' component={MapView} exact/>
                                            <Redirect to={getUsersRole() === "QC" ?'/dashboard':'/gps-data'}/>
                                        </Switch>
                                    }
                            </div>
                            }

                            {!Auth.getToken() &&
                                <Switch>
                                    <Route path='/login' component={LoginRouter}/>
                                    <Redirect to='/login'/>
                                </Switch>
                            }

                        </div>
                    )}/>
            </div>
        </BrowserRouter>
    )
}
