import request from '../request'

export const create = result => {
    return request.post('results/bulk/update', result)
}

export const search = query => {
    return request.post('results/search', query)
}

export const searchResultSummary = query => {
    return request.post('results/search/group', query)
}


export const searchPassRate = query => {
    return request.post('results/search/pass-rate', query)
}


export const searchGpsData = query => {
    return request.post('gps-data2/search',query)
}

export const updateGpsData = (id,req) => {
    return request.patch('gps-data2/'+id,req)
}
export const getDashboardSummary = () => {
    return request.get('gps-data2/summary')
}

export const deleteGpsData = (id) => {
    return request.delete('gps-data2/' + id)
}
export const uploadFile = (data) => {
    return request.postFormData('gps-data2/upload-file',data)
}

