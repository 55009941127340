import React, {Fragment, useEffect, useRef, useState} from 'react'
import _, {get} from 'lodash'
import {connect, useDispatch} from 'react-redux'

import {createResult, searchGpsData, searchPassRate, searchResults, searchResultSummary, searchUsers} from '../actions'
import {Auth, Input, Select} from 'common-modules'
import dataSource from "../../Main/dataSource";
import resultsReducer from "../reducer";
import {getUsersRole, isAdminUser, userRoles} from "../../Utils/utils";
import {faDownload, faEye, faRefresh} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import TableToExcel from "@stanlystark/table-to-excel";

const SearchBox = () => {
    const dispatch = useDispatch()
    const initSearchReq ={}
    const [searchReq,setSearchReq] = useState(initSearchReq)



    const handleOnChange = (value,refer) => {
        searchReq[refer] = value
        setSearchReq({...searchReq})
    }

    useEffect(()=>{
        dispatch(searchUsers(initSearchReq))
    },[])

    const clearSearch = () =>{
        setSearchReq({...initSearchReq});
        dispatch(searchUsers(initSearchReq))
    }

    const onSearch = () => {
        dispatch(searchUsers(searchReq))
    }

    return (

        <Fragment>
            <div className="row">
                <div className="col-md-12 text-center mt-5 " style={{justifyContent:'center',display: 'flex'}}>
                        <div className='col-md-12'>
                            <div className="card bg-lightest-blue mb-3" style={{maxWidth:'100%'}}>
                                <div className="card-body">
                                    <h5 className='text-left'>Search Results</h5>
                                    <div className='row '>

                                        <div className='col-md-3 mt-2'>
                                            <Input

                                                refer='username'
                                                handleOnChange={handleOnChange}
                                                value={get(searchReq, 'username')}
                                                placeholder='Username'
                                                className="form-control mt-1"/>
                                        </div>

                                        <div className='col-md-3 mt-2'>
                                            <Select
                                                refer='role'
                                                handleOnChange={handleOnChange}
                                                value={get(searchReq, 'role')}
                                                placeholder='User Type'
                                                items = {userRoles}
                                                className="form-control mt-1"/>
                                        </div>

                                        <div className='col-md-3 mt-2'>

                                        </div>
                                        <div className="col-md-3 mt-2">
                                            <div className={'row'}>
                                                <button
                                                    style={{width:'50%'}}
                                                    onClick={onSearch}
                                                    className='btn btn-success btn-block'>
                                                    Search
                                                </button>
                                                <button
                                                    style={{width:'20%', margin:4}}
                                                    onClick={clearSearch}
                                                    className='btn btn-danger btn-block'>
                                                    <FontAwesomeIcon icon={faRefresh} />
                                                </button>
                                            </div>
                                        </div>

                                    </div>
                                </div>



                            </div>
                        </div>
                </div>
            </div>
        </Fragment>
    )
}

export default connect(state => ({
    lgaReducer:state.userReducer,
}))(SearchBox)
